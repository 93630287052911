import React, {createContext, useContext, useState} from 'react';

const Context = createContext();
const ContextAction = createContext();

export const GlobalFormProvider = ({children}) => {
  const [startWorkerForm, setStartWorkerForm] = useState({
    profile_image: '',
    service_category: '',
    business_phone_number: '',
    address: '',
    biography: ''
  });
  const [formType, setFormType] = useState('default');

  const handleSubmit = (values, actions) => {
    // Handle form submission logic based on formType
    switch (formType) {
      case 'service':
        console.log('service');
        break;
      case 'address':
        console.log('address');
        break;
      default:
        // Handle default form submission
        console.log(values);
        actions.setSubmitting(false);
    }
  };

  return (
    <Context.Provider value={{startWorkerForm, formType, setFormType}}>
      <ContextAction.Provider value={{setStartWorkerForm, handleSubmit}}>
        {children}
      </ContextAction.Provider>
    </Context.Provider>
  );
};

export const useFormContext = () => {
  return useContext(Context);
};

export const useFormAction = () => {
  return useContext(ContextAction);
};

export default GlobalFormProvider;
