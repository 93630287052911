import {useJWTAuth, useJWTAuthActions} from 'apps/accounts/JWTAuthProvider';

export const useAuthUser = () => {
  const {isLoading, isAuthenticated, user} = useJWTAuth();
  return {user, isLoading, isAuthenticated};
};

export const useAuthMethod = () => {
  const {
    signUpUser,
    logout,
    signInUser,
    callGoogleApi,
    updateUser,
    changePassword,
    forgotPassword,
    resetChangePassword,
    validateEmail,
    getUserMe
  } = useJWTAuthActions();

  return {
    callGoogleApi,
    signUpUser,
    signInUser,
    updateUser,
    changePassword,
    forgotPassword,
    resetChangePassword,
    validateEmail,
    logout,
    getUserMe
  };
};
