import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'apps/apis';
import {useNotificationMethod} from '@myservice/hooks';
import extractError from '@myservice/helper/extractError';

const PaymentProviderContext = createContext();
const PaymentProviderActionsContext = createContext();

const PaymentProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();
  const [payment, setPayment] = useState({
    payLoading: false,
    transactionData: null,
    transactionUrl: null
  });

  const fetchData = async (url, method = 'GET', payload = null) => {
    try {
      setPayment((prev) => ({
        ...prev,
        payLoading: true
      }));
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });

      setPayment((prev) => ({
        ...prev,
        payLoading: false
      }));
      return response.data;
    } catch (error) {
      setPayment((prev) => ({
        ...prev,
        payLoading: false
      }));
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  // Function to initiate payment
  const initiatePayment = async (params) => {
    return fetchData('payfast/initiate-payment/', 'POST', params);
  };

  const processPayFastPayment = async (params) => {
    try {
      const tokenResponse = await initiatePayment(params);

      const {transaction_url, transaction_data} = tokenResponse;

      if (!transaction_url || !transaction_data.TOKEN) {
        showNotification('Failed to initiate payment. Please try again.');
        console.error(
          'Invalid response from payment initiation:',
          tokenResponse
        );
        return;
      }

      setPayment((prev) => ({
        ...prev,
        transactionData: transaction_data,
        transactionUrl: transaction_url
      }));
    } catch (error) {
      showNotification(
        'An error occurred while processing your payment. Please try again.'
      );
      console.error('Error initiating payment:', error);
    }
  };

  return (
    <PaymentProviderContext.Provider value={{...payment}}>
      <PaymentProviderActionsContext.Provider value={{processPayFastPayment}}>
        {children}
      </PaymentProviderActionsContext.Provider>
    </PaymentProviderContext.Provider>
  );
};

export const usePaymentContext = () => useContext(PaymentProviderContext);

export const usePaymentActionContext = () =>
  useContext(PaymentProviderActionsContext);

export default PaymentProvider;

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired
};
