export default function convertDateToReadable(dateString) {
  if (dateString) {
    const [year, month, day] = dateString.split('-');

    // Create an array of month names
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    // Convert the month from numeric to its corresponding name
    const monthName = monthNames[parseInt(month, 10) - 1];

    // Construct the readable date format
    const readableDate = `${monthName} ${parseInt(day, 10)}, ${year}`;

    return readableDate;
  } else {
    return null;
  }
}
