function convertTimeSlotTo12HourFormat(
  timeSlot,
  startFlag = false,
  endFlag = true
) {
  let {start, end} = timeSlot;
  function convert24to12(time24, showPeriod = true) {
    if (time24) {
      let [hours, minutes] = time24.split(':').map(Number);

      if (hours === 12) {
        // 12:00 is just 12:00 (implicitly noon)
        return (
          `12:${minutes.toString().padStart(2, '0')}` +
          (showPeriod ? ' PM' : '')
        );
      } else if (hours === 0) {
        // 00:00 becomes 12:00 (implicitly midnight)
        return (
          `12:${minutes.toString().padStart(2, '0')}` +
          (showPeriod ? ' AM' : '')
        );
      } else {
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 13-23 to 1-11
        return (
          `${hours}:${minutes.toString().padStart(2, '0')}` +
          (showPeriod ? ` ${period}` : '')
        );
      }
    }
  }

  return {
    start: convert24to12(start, startFlag),
    end: convert24to12(end, endFlag)
  };
}

export default convertTimeSlotTo12HourFormat;
