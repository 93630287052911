export default function addressOfferDropDown(address) {
  let dropdownList = [];
  for (let item of address) {
    let address = {
      label:
        item.address_line_one +
        ' ' +
        item.street +
        ' ' +
        item.city +
        ' ' +
        item.country,
      value: item.id
    };
    dropdownList.push(address);
  }
  return dropdownList;
}
