const ceilingCompletion = (originalDays, targetArea) => {
  const originalArea = 12 * 12;

  // Calculate the work rate of one worker in square feet per day
  const workRatePerDay = originalArea / parseInt(originalDays);

  // Calculate the total work needed in square feet per day
  const totalWorkPerDay = parseInt(targetArea) / parseInt(originalDays);

  // Calculate the number of workers needed
  const workersNeeded = totalWorkPerDay / workRatePerDay;

  return Math.ceil(workersNeeded);
};

export default ceilingCompletion;
