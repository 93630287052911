import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  GEOAPIFY,
  GEOAPI_URL,
  UNSPLASH_URL,
  UNSPLASH_API_KEY
} from 'apps/apis/allApis';

const ThirdpartyAPIContext = createContext();
const ThirdpartyAPIActionsContext = createContext();

const ThirdpartyAPI = ({children}) => {
  const [isLoading, setLoading] = useState(false);
  const [suggestingAddresses, setSuggestingAddresses] = useState();
  const [formattedAddress, setFormattedAddress] = useState('');
  const [geoApiAddress, setGeoApiAddress] = useState({
    address_line_one: '',
    address_line_two: '',
    street: '',
    city: '',
    postal_code: '',
    province_or_state: '',
    country: ''
  });

  async function fetchData(url, method = 'GET', params = {}, headers = {}) {
    setLoading(true);
    try {
      const response = await axios({
        method: method,
        url: url,
        params: params,
        headers: headers
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw new Error(`Error fetching data: ${error.message}`);
    }
  }

  const locateMe = async () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        async (position) => {
          const {latitude, longitude} = position.coords;
          setLoading(false);

          const data = await fetchData(
            `${GEOAPI_URL}reverse?lat=${latitude}&lon=${longitude}&apiKey=${GEOAPIFY}`
          );

          if (data.features && data.features.length > 0) {
            const adrs = data.features[0]?.properties;
            setFormattedAddress(adrs.formatted);
            setGeoApiAddress((prev) => ({
              ...prev,
              address_line_one: adrs?.address_line1,
              address_line_two: adrs?.address_line2,
              street: adrs?.street,
              city: adrs?.city,
              postal_code: adrs?.postcode,
              province_or_state: adrs?.state,
              country: adrs?.country
            }));

            return data;
          } else {
            console.error('No address found for the provided coordinates.');
          }
        },
        (error) => {
          console.error('Error getting current position:', error);
        }
      );
    }
  };

  const findAddress = async (name) => {
    if (name) {
      const data = await fetchData(
        `${GEOAPI_URL}autocomplete?text=${name}&apiKey=${GEOAPIFY}`
      );

      setSuggestingAddresses(data?.features);
    }
  };

  const fetchImages = async (query) => {
    const URL = `${UNSPLASH_URL}?query=${encodeURIComponent(query)}&per_page=1`;
    const params = {
      query: query,
      per_page: 1
    };
    const headers = {
      Authorization: `Client-ID ${UNSPLASH_API_KEY}`
    };

    try {
      const response = await fetchData(URL, 'GET', params, headers);
      return response;
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  return (
    <ThirdpartyAPIContext.Provider
      value={{isLoading, suggestingAddresses, formattedAddress, geoApiAddress}}
    >
      <ThirdpartyAPIActionsContext.Provider
        value={{
          locateMe,
          findAddress,
          fetchImages,
          setFormattedAddress,
          setGeoApiAddress
        }}
      >
        {children}
      </ThirdpartyAPIActionsContext.Provider>
    </ThirdpartyAPIContext.Provider>
  );
};

export const useThirdpartyAPICxt = () => useContext(ThirdpartyAPIContext);

export const useThirdpartyAPIActions = () =>
  useContext(ThirdpartyAPIActionsContext);

export default ThirdpartyAPI;

ThirdpartyAPI.propTypes = {
  children: PropTypes.node.isRequired
};
