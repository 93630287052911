import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../apis';
import {GET_ALL_ADDRESS} from '../apis/allApis';
import {useNotificationMethod} from '@myservice/hooks/useNotification';
import {useAuthUser} from '@myservice/hooks/AuthUser';
import extractError from '@myservice/helper/extractError';

const AddressContext = createContext();
const AddressActionsContext = createContext();

const AddressProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();
  const [retrivedAddress, setRetrivedAddress] = useState();
  const [newCreatedAddress, setNewCreatedAddress] = useState(null);
  const {isAuthenticated} = useAuthUser();
  const [address, setAddress] = useState({
    count: 0,
    allAddress: []
  });

  const getAllAddress = async (page, page_size) => {
    try {
      const {data} = await axiosInstance.get(
        `${GET_ALL_ADDRESS}?page=${page}&page_size=${page_size}`
      );
      setAddress(() => ({
        ...address,
        allAddress: data?.results,
        count: data?.count
      }));
    } catch (error) {
      console.error(error.response.data);
    }
  };

  const createAddress = async (addressData) => {
    try {
      const resp = await axiosInstance.post(GET_ALL_ADDRESS, addressData);
      if (resp?.data?.id) {
        setNewCreatedAddress(resp?.data);
      }
      return resp;
    } catch (error) {
      showNotification(extractError(error.response.data));
      console.error(error);
    }
  };

  const patchAddress = async (addressData) => {
    if (addressData?.id) {
      try {
        return await axiosInstance.put(
          `${GET_ALL_ADDRESS}${addressData.id}/`,
          addressData
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const destroyAddress = async (addressId) => {
    if (addressId) {
      try {
        const res = await axiosInstance.delete(
          `${GET_ALL_ADDRESS}${addressId}`
        );
        showNotification('Address deleted successfully');
        return res;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const retriveAddress = async (id) => {
    if (id) {
      try {
        const {data} = await axiosInstance.get(`${GET_ALL_ADDRESS}${id}`);
        setRetrivedAddress(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAllAddress(1, 25);
    }
  }, [isAuthenticated]);

  return (
    <AddressContext.Provider
      value={{...address, retrivedAddress, newCreatedAddress}}
    >
      <AddressActionsContext.Provider
        value={{
          createAddress,
          getAllAddress,
          patchAddress,
          destroyAddress,
          retriveAddress
        }}
      >
        {children}
      </AddressActionsContext.Provider>
    </AddressContext.Provider>
  );
};

export const useAddressContext = () => useContext(AddressContext);
export const useAddresActionsContext = () => useContext(AddressActionsContext);

export default AddressProvider;

AddressProvider.propTypes = {
  children: PropTypes.node.isRequired
};
