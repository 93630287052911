export default function calculateYears(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  if (inputDate.getFullYear() === currentDate.getFullYear()) {
    return '1st year';
  } else {
    const yearDifference = currentDate.getFullYear() - inputDate.getFullYear();
    return yearDifference;
  }
}
