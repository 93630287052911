import {useWorkers, useWorkerActions} from 'apps/worker/WorkerProvider';
import {workerInitialFormat, serviceForm} from '../helper';
import {useAuthUser} from './AuthUser';

export const useWorker = () => {
  const {isLoading, worker, workers, bussiness, searchParam, chefDish} =
    useWorkers();

  const {user} = useAuthUser();
  return {
    isLoading,
    chefDish,
    worker,
    workers,
    searchParam,
    bussiness,
    starterForm: serviceForm(worker, user),
    initailForm: workerInitialFormat(worker)
  };
};

export const useWorkerMethod = () => {
  const {
    switchAccount,
    postWorkerData,
    getWorkerData,
    retrieveWorker,
    getWorkersData,
    setSearchParam,
    getAllBussiness,
    getFeaturePackages,
    getFeaturePainters,
    getFeatureTravelTours
  } = useWorkerActions();
  return {
    switchAccount,
    getWorkerData,
    postWorkerData,
    retrieveWorker,
    getWorkersData,
    setSearchParam,
    getAllBussiness,
    getFeaturePainters,
    getFeaturePackages,
    getFeatureTravelTours
  };
};
