/**
 * Replaces dashes with slashes in a date string.
 * @param {string} dateString - The date string with dashes.
 * @return {string} - The date string with slashes.
 */
function replaceDashesWithSlashes(dateString) {
  // Check if the input is a valid string
  if (typeof dateString !== 'string') {
    throw new Error('Input must be a string');
  }

  // Replace all dashes with slashes
  return dateString.replace(/-/g, '/');
}

export default replaceDashesWithSlashes;
