import React, {createContext, useContext, useState} from 'react';

import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {ACCOMMODATION, FEATURE_ACCOMMODATION} from 'apps/apis/allApis';

const AccommodationContext = createContext();
const AccommodationAction = createContext();

export const AccommodationProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();

  const [loading, setLoading] = useState(false);
  const [accommodationState, setAccommodationState] = useState({
    accommodations: [],
    previous: null,
    next: null,
    count: 0,
    currentPage: 1
  });

  const [featureAccommodation, setFeatureAccommodation] = useState({
    accommodations: [],
    previous: null,
    next: null,
    count: 0,
    currentPage: 1
  });

  const fetchData = async (url, method = 'GET', payload = null, isFormData) => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method,
        url,
        data: payload,
        headers: isFormData ? {'Content-Type': 'multipart/form-data'} : {}
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const getFeatureAccommodations = async (requestedPage = null) => {
    const page =
      requestedPage ||
      (featureAccommodation.next
        ? featureAccommodation.currentPage + 1
        : featureAccommodation.currentPage);

    const url = requestedPage
      ? `${FEATURE_ACCOMMODATION}?page=${requestedPage}&page_size=24`
      : featureAccommodation.next ||
        `${FEATURE_ACCOMMODATION}?page=${page}&page_size=24`;

    const data = await fetchData(url);
    if (!data) return null;

    setFeatureAccommodation((prev) => ({
      ...prev,
      accommodations:
        page > 1
          ? [
              ...prev.accommodations,
              ...(data.results || []).filter(
                (newAccom) =>
                  !prev.accommodations.some(
                    (existingAccom) => existingAccom.id === newAccom.id
                  )
              )
            ]
          : data.results || [],
      previous: data.previous,
      next: data.next,
      count: data.count || 0,
      currentPage: page
    }));

    return data;
  };

  const getAccommodations = async (requestedPage = null) => {
    const page =
      requestedPage ||
      (accommodationState.next
        ? accommodationState.currentPage + 1
        : accommodationState.currentPage);

    const url = requestedPage
      ? `${ACCOMMODATION}?page=${requestedPage}&page_size=8`
      : accommodationState.next || `${ACCOMMODATION}?page=${page}&page_size=8`;

    const data = await fetchData(url);
    if (!data) return null;

    setAccommodationState((prev) => ({
      ...prev,
      accommodations:
        page > 1
          ? [
              ...prev.accommodations,
              ...(data.results || []).filter(
                (newAccom) =>
                  !prev.accommodations.some(
                    (existingAccom) => existingAccom.id === newAccom.id
                  )
              )
            ]
          : data.results || [],
      previous: data.previous,
      next: data.next,
      count: data.count || 0,
      currentPage: page
    }));

    return data;
  };

  return (
    <AccommodationContext.Provider
      value={{accommodationState, featureAccommodation, loading}}
    >
      <AccommodationAction.Provider
        value={{
          getAccommodations,
          getFeatureAccommodations
        }}
      >
        {children}
      </AccommodationAction.Provider>
    </AccommodationContext.Provider>
  );
};

export const useAccommodationContext = () => useContext(AccommodationContext);
export const useAccommodationActions = () => useContext(AccommodationAction);

export default AccommodationProvider;
