import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: ${({$active, theme}) =>
    $active ? theme.secondary.shade[500] : '#fff'};
  color: ${({$active, theme}) => ($active ? '#fff' : theme.secondary.default)};
  border: 1px solid ${({theme}) => theme.secondary.default};
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 0.3rem;
  cursor: pointer;
  &:hover {
    background-color: ${({$active, theme}) =>
      $active ? theme.secondary.default : '#f0f0f0'};
  }
`;

const Pagination = ({currentPage, totalPages, itemsPerPage, onPageChange}) => {
  const numPages = Math.ceil(totalPages / itemsPerPage);
  const pages = Array.from({length: numPages}, (_, index) => index + 1);

  return (
    <PaginationContainer>
      {pages.map((page) => (
        <PageButton
          key={page}
          $active={currentPage === page}
          onClick={() => onPageChange(page)}
        >
          {page}
        </PageButton>
      ))}
    </PaginationContainer>
  );
};

export default Pagination;
