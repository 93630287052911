import React from 'react';
import {DialogOverlay, Dialog} from 'styles/common';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.25rem;

  button {
    cursor: pointer;
    padding: 0.7rem 3rem;
    border: none;
    background-color: transparent;
    border: 1px solid ${({theme}) => theme.bg[400]};
    border-radius: 3rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: ${({theme}) => theme.clr[400]};
  }
  > button:first-child {
    font-size: 1.25rem;
    background-color: ${({theme, type}) =>
      type === 'error' ? theme.error.font : theme.secondary.default};
    color: ${({theme}) => theme.error.bg};
    font-weight: 700;

    border: none;
  }
`;

const ConfirmationBox = ({message, onConfirm, isOpen, setOpen, type}) => {
  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <>
      {isOpen && (
        <DialogOverlay>
          <Dialog
            maxWidth={'26rem'}
            sx={{
              borderRadius: '1rem',
              padding: '1.5rem'
            }}
          >
            <p>{message}</p>
            <ButtonWrapper type={type}>
              <button onClick={handleConfirm}>Confirm</button>
              <button onClick={() => setOpen(false)}>Cancel</button>
            </ButtonWrapper>
          </Dialog>
        </DialogOverlay>
      )}
    </>
  );
};

export default ConfirmationBox;
