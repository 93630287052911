import {
  useServices,
  useServicesActions
} from 'apps/services/AllServicesProvider';
import servicesOfferDropDown from '../helper/servicesOfferDropDown';

export const useService = () => {
  const {availableServices, queryResult} = useServices();
  return {
    availableServices,
    queryResult,
    servicesOfferDropDown: servicesOfferDropDown(availableServices)
  };
};

export const useServiceMethod = () => {
  const {getAvailableServices, setServices, searchServices} =
    useServicesActions();

  return {getAvailableServices, searchServices, setServices};
};
