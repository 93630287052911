import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance, {axiosPubInstance} from '../apis';
import {PAINTER_QUERY, BIDS} from '../apis/allApis';
import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';

const PainterContext = createContext();
const PainterActionsContext = createContext();

const PainterProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();

  const [painterLoading, setPainterLoading] = useState(false);
  const [painters, setPainters] = useState({
    allPainter: [],
    allPaintingQueries: [],
    bids: [],
    selfBids: [],
    paintingQueries: JSON.parse(localStorage.getItem('PId')) ?? null
  });

  const getPaintingQueryById = async (id) => {
    setPainterLoading(true);
    try {
      const {data} = await axiosPubInstance.get(PAINTER_QUERY + `${id}/`);
      setPainters(() => ({
        ...painters,
        paintingQueries: data
      }));
      setPainterLoading(false);
    } catch (error) {
      if (extractError(error.response.data) === 'Not found.') {
        localStorage.removeItem('PId');
      }
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
      console.error(error.response.data);
    }
  };

  const patchPainter = async (queryPayload, id) => {
    setPainterLoading(true);

    try {
      const {data} = await axiosInstance.put(
        PAINTER_QUERY + `${painters?.paintingQueries?.id}/`,
        queryPayload,
        {headers: {'Content-Type': 'multipart/form-data'}}
      );
      setPainters(() => ({
        ...painters,
        paintingQueries: data
      }));
      setPainterLoading(false);
      return data;
    } catch (error) {
      console.error(error);
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
    }
  };

  const createPainterQuestions = async (queryPayload) => {
    setPainterLoading(true);
    try {
      const {data} = await axiosInstance.post(PAINTER_QUERY, queryPayload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setPainters(() => ({
        ...painters,
        paintingQueries: data
      }));
      setPainterLoading(false);
      return data;
    } catch (error) {
      showNotification(extractError(error.response.data));
      console.error(error);
      setPainterLoading(false);
    }
  };

  const addPaintingBid = async (payload) => {
    try {
      const {data} = await axiosInstance.post(BIDS, payload);
      setPainterLoading(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateBid = async (payload) => {
    try {
      const {data} = await axiosInstance.patch(
        `${BIDS}${payload.id}/`,
        payload
      );
      setPainterLoading(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBids = async () => {
    setPainterLoading(true);
    try {
      const {data} = await axiosInstance.get(`${BIDS}all`);
      setPainters(() => ({
        ...painters,
        bids: data
      }));
      setPainterLoading(false);
    } catch (error) {
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
      console.error(error.response.data);
    }
  };

  const offerBid = async (payload) => {
    setPainterLoading(true);
    try {
      const {data} = await axiosInstance.post(`${BIDS}biders`, payload);
      setPainterLoading(false);
      getAllBids();
      return data;
    } catch (error) {
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
      console.error(error.response.data);
    }
  };

  const updateBidder = async (payload) => {
    setPainterLoading(true);
    try {
      const {data} = await axiosInstance.patch(
        `${BIDS}biders/${payload.id}`,
        payload
      );
      setPainterLoading(false);
      getAllBids();
      return data;
    } catch (error) {
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
      console.error(error.response.data);
    }
  };

  const getSelfBids = async () => {
    setPainterLoading(true);
    try {
      const {data} = await axiosInstance.get(`${BIDS}self`);
      setPainters(() => ({
        ...painters,
        selfBids: data
      }));
      setPainterLoading(false);
    } catch (error) {
      showNotification(extractError(error.response.data));
      setPainterLoading(false);
      console.error(error.response.data);
    }
  };

  return (
    <PainterContext.Provider value={{...painters, painterLoading}}>
      <PainterActionsContext.Provider
        value={{
          createPainterQuestions,
          patchPainter,
          getPaintingQueryById,
          offerBid,
          addPaintingBid,
          getAllBids,
          getSelfBids,
          updateBidder,
          updateBid
        }}
      >
        {children}
      </PainterActionsContext.Provider>
    </PainterContext.Provider>
  );
};

export const usePainterContext = () => useContext(PainterContext);
export const usePainterActionContext = () => useContext(PainterActionsContext);

export default PainterProvider;

PainterProvider.propTypes = {
  children: PropTypes.node.isRequired
};
