const theme = {
  font: 'Be Vietnam Pro, Albert Sans, sans-serif',

  primary: {
    // The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc.
    default: '#000A14',
    shade: {
      25: '#E5EFF9',
      50: '#B3D1EF',
      100: '#9AC1EA',
      200: '#68A3E0',
      300: '#4F93DB',
      400: '#3684D6',
      500: '#1D75D1',
      600: '#0466CC',
      700: '#035BB7',
      800: '#0351A3',
      900: '#02478E',
      1000: '#023D7A',
      1100: '#012851',
      1200: '#011E3D',
      1300: '#000A14'
    }
  },

  //   Along with primary colors, it's helpful to have a selection of secondary colors to use in components such as pills, alerts and labels.

  secondary: {
    default: '#13ad5c',
    shade: {
      25: '#EFFDF7',
      50: '#C3E1CB',
      100: '#9CCEA8',
      200: '#88C497',
      300: '#75BB85',
      400: '#61B174',
      500: '#4DA763',
      600: '#3A9E52',
      700: '#348E49',
      800: '#2E7E41',
      900: '#286E39',
      1000: '#225E31',
      1100: '#1D4F29',
      1200: '#112F18',
      1300: '#112F18'
    }
  },

  error: {
    bg: '#FEF0F0',
    font: '#EA3B3B'
  },

  info: {
    bg: '#ebf5ff',
    font: '#2F80ED'
  },

  success: {
    bg: '#d4ffee',
    font: '#27C281'
  },

  warning: {
    bg: '#FFFBF1',
    font: '#F0AF23'
  },

  hiringStatus: {
    Waiting: {bg: '#FFFBF1', font: '#F0AF23'}, // Warning colors
    Interview: {bg: '#ebf5ff', font: '#2F80ED'}, // Info colors
    Accepted: {bg: '#d4ffee', font: '#27C281'}, // Success colors
    Rejected: {bg: '#FEF0F0', font: '#EA3B3B'}, // Error colors
    Complete: {bg: '#d4ffee', font: '#27C281'} // Success colors
  },

  clr: {
    100: '#718096',
    200: '#4A5568',
    300: '#2D3748',
    400: '#0F0A0A'
  },
  bg: {
    100: '#F7FAFC',
    200: '#F6F6F6',
    300: '#F3F3F3',
    400: '#E2E8F0',
    500: '#EBEBEB'
  },
  gray: {
    100: '#E3E3E3',
    200: '#CFCFCF',
    300: '#BEBEBE',
    400: '#b2b2b2 ',
    500: '#828282'
  }
};

export default theme;
