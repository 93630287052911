import extractProfession from './extractProfession';

export default function initialFormat(worker) {
  const paint = worker?.painter?.length > 0;
  const painter = worker?.painter?.[0];
  const tour_guide = worker?.tour_guide?.[0];
  const attorney = worker?.attorney?.[0];
  const quran_teacher = worker?.quran_teacher?.[0];
  const chef = worker?.chef?.[0];
  const marble_specialist = worker?.marble_specialist?.[0];
  const ceiling_specialist = worker?.ceiling_specialist?.[0];
  const travel_tour_agency = worker?.travel_tour_agency?.[0];
  const hotel_resort = worker?.hotel_resort?.[0];

  const initailForm = {
    service_category: worker?.service_category ?? [],
    business_phone_number: worker?.business_phone_number ?? '',
    address: worker?.address ?? '',
    national_id_card_number: worker?.national_id_card_number ?? '',
    skills: worker?.skills ?? '',
    certifications: worker?.certifications ?? '',
    biography: worker?.biography ?? '',

    painter: {
      years_experience: paint ? painter.years_experience : '',
      areas_of_expertise: paint ? painter.areas_of_expertise : '',
      under_coat_labor_charges: paint ? painter.under_coat_labor_charges : '',
      wall_painting_labor_charges: paint
        ? painter.wall_painting_labor_charges
        : '',
      wood_painting_labor_charges: paint
        ? painter.wood_painting_labor_charges
        : '',

      wood_polishing_labor_charges: paint
        ? painter.wood_polishing_labor_charges
        : '',
      texture_labor_charges: paint ? painter?.texture_labor_charges : {},

      charge_type: 'ft²',
      charge: paint ? painter.charge : '',
      material_include: paint ? painter.material_include : '',
      under_coat_with_material: paint ? painter.under_coat_with_material : null,
      wall_painting_with_material: paint
        ? painter.wall_painting_with_material
        : null,
      wood_painting_with_material: paint
        ? painter.wood_painting_with_material
        : null,
      wood_polishing_with_material: paint
        ? painter.wood_polishing_with_material
        : null,
      paint_name: paint ? painter.paint_name : null,
      texture_with_material: paint ? painter.texture_with_material : {}
    },

    tour_guide: {
      years_experience: tour_guide?.years_experience ?? '',
      charge: tour_guide?.charge ?? '',
      charge_type: attorney?.charge_type ?? 'hour',
      education: tour_guide?.education ?? '',
      areas_of_expertise: tour_guide?.areas_of_expertise ?? '',
      languages: tour_guide?.languages ?? '',
      offer_group_trip: tour_guide?.offer_group_trip ?? false
    },

    attorney: {
      firm_name: attorney?.firm_name ?? '',
      areas_of_expertise: attorney?.areas_of_expertise ?? '',
      jurisdictions: attorney?.jurisdictions ?? '',
      education: attorney?.education ?? '',
      years_experience: attorney?.years_experience ?? '',
      charge: attorney?.charge ?? '',
      charge_type: attorney?.charge_type ?? 'hour',
      languages: attorney?.languages ?? ''
    },

    quran_teacher: {
      areas_of_expertise: quran_teacher?.areas_of_expertise ?? '',
      education: quran_teacher?.education ?? '',
      years_experience: quran_teacher?.years_experience ?? '',
      charge: quran_teacher?.charge ?? '',
      languages: quran_teacher?.languages ?? '',
      teaching_methods: quran_teacher?.teaching_methods ?? '',
      virtual_classes: quran_teacher?.virtual_classes ?? false,
      offer_package: quran_teacher?.offer_package ?? false
    },

    chef: {
      charge: chef?.charge ?? '',
      areas_of_expertise: chef?.areas_of_expertise ?? '',
      languages: chef?.languages ?? '',
      years_experience: chef?.years_experience ?? '',
      offering_dishes: chef?.offering_dishes ?? '',
      chef_offers: chef?.chef_offers ?? ''
    },

    'marble_&_tile_specialist': {
      years_experience: marble_specialist?.years_experience ?? '',
      areas_of_expertise: marble_specialist?.areas_of_expertise ?? '',
      stone_types: marble_specialist?.stone_types ?? '',
      charge: marble_specialist?.charge ?? '',
      charge_type: marble_specialist?.charge_type ?? 'hour',
      special_designs_and_inlays: {}
    },

    ceiling_specialist: {
      years_experience: ceiling_specialist?.years_experience ?? '',
      charge: ceiling_specialist?.charge ?? '',
      charge_type: ceiling_specialist?.charge_type ?? 'hour',
      name: ceiling_specialist?.name ?? ''
    },
    'travels_&_tours_agency': {
      years_experience: travel_tour_agency?.years_experience ?? '',
      areas_of_expertise: travel_tour_agency?.areas_of_expertise ?? ''
    },

    'hoteling_&_resort_service': {
      property_type: hotel_resort?.property_type ?? '',
      environment: hotel_resort?.environment ?? '',
      security: hotel_resort?.security ?? '',
      amenities: hotel_resort?.amenities ?? '',
      bedrooms: hotel_resort?.bedrooms ?? '',
      bathrooms: hotel_resort?.bathrooms ?? ''
    }
  };

  return initailForm;
}

export function serviceForm(worker) {
  const prof = extractProfession(worker);
  const initailForm = {
    Painter: {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        material_include: prof?.material_include ?? '',
        charge: prof?.charge ?? ''
      },
      labour: {
        under_coat_labor_charges: prof?.under_coat_labor_charges ?? '',
        wall_painting_labor_charges: prof?.wall_painting_labor_charges ?? '',
        wood_painting_labor_charges: prof?.wood_painting_labor_charges ?? '',
        wood_polishing_labor_charges: prof?.wood_polishing_labor_charges ?? ''
      },
      material: {
        paint_name: prof?.paint_name ?? '',
        wall_painting_with_material: prof?.wall_painting_with_material ?? '',
        under_coat_with_material: prof?.under_coat_with_material ?? '',
        wood_painting_with_material: prof?.wood_painting_with_material ?? '',
        wood_polishing_with_material: prof?.wood_polishing_with_material ?? ''
      }
    },
    Attorney: {
      about: {
        firm_name: prof?.firm_name ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        jurisdictions: prof?.jurisdictions ?? '',
        education: prof?.education ?? '',
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? ''
      },

      pricing: {
        charge_type: prof?.charge_type ?? 'hour'
      },

      education: {
        education: ''
      }
    },

    'Quran Teacher': {
      about: {
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? '',
        teaching_methods: prof?.teaching_methods ?? '',
        virtual_classes: prof?.virtual_classes ?? false,
        offer_package: prof?.offer_package ?? false
      },

      education: {
        education: ''
      },
      pricing: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    'Marble & Tile Specialist': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      },

      type: {
        name: ''
      },

      fitting_pricing: {
        floor_fitting: '',
        wall_cladding: '',
        staircase_fitting: '',
        countertops: '',
        frontend_elevation: ''
      },

      edging_pricing: {
        miter_edge: '',
        ogee_on_eased_edge: '',
        ogee_on_half_round_edge: '',
        ogee_on_inverted_ogee: '',
        ogee_on_ogee_edge: '',
        ogee_on_ogee_shifted_edge: '',
        ogee_on_quarter_round_edge: '',
        quarter_round_on_half_round_edge: '',
        triple_pencil_on_half_round_edge: '',
        half_bevel_on_eased: '',
        half_round_on_eased: '',
        eased_on_eased: '',
        chiseled: '',
        triple_pencil: '',
        cove_ogee: '',
        ogee: '',
        half_round: '',
        half_bevel: '',
        quarter_round: '',
        ease: ''
      },

      polishing: {
        standard_polishing: '',
        high_gloss_polishing: '',
        honing_polishing: '',
        matte_polishing: '',
        satin_polishing: '',
        brushed_polishing: '',
        tumbled_polishing: '',
        leathered_polishing: '',
        glazed_polishing: '',
        bush_hammered_polishing: ''
      },

      special_designs_and_inlays: {}
    },

    'Tour Guide': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        languages: prof?.languages ?? ''
      },

      education: {
        education: ''
      },

      pricing: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    Chef: {
      charges: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      },
      about: {
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? ''
      },

      expertise: {
        areas_of_expertise: prof?.expertise ?? ''
      },

      dishes: {
        offering_dishes: {}
      }
    },

    'Ceiling Specialist': {
      about: {
        years_experience: prof?.years_experience ?? '',
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    'Travels & Tours Agency': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? ''
      }
    },

    'Hoteling & Resort Service': {
      about: {
        property_type: prof?.property_type ?? '',
        environment: prof?.environment ?? '',
        security: prof?.security ?? '',
        amenities: prof?.amenities ?? '',
        bedrooms: prof?.bedrooms ?? '',
        bathrooms: prof?.bathrooms ?? ''
      }
    }
  };

  return initailForm;
}
