export default function calculateDaysBetweenDates(date1, date2) {
  // Convert the date strings to Date objects
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Check if the dates are the same
  if (d1.getTime() === d2.getTime() || !date2) {
    return 1;
  } else {
    const timeDifference = d2.getTime() - d1.getTime();

    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference + 1;
  }
}
