export default function calculateDuration(
  time1_str,
  time2_str,
  includeString = true,
  returnType = 'hour'
) {
  // Create Date objects from the time strings
  let time1 = new Date('1970-01-01T' + time1_str + 'Z');
  let time2 = new Date('1970-01-01T' + time2_str + 'Z');

  // Calculate the difference in milliseconds
  let duration_ms = Math.abs(time2 - time1);

  if (returnType === 'min') {
    // Convert milliseconds into minutes
    let minutes = Math.floor(duration_ms / (1000 * 60));
    if (includeString) {
      // Prepare the output string
      let duration_str = '';
      if (minutes > 0) {
        duration_str += minutes + ' minute';
        if (minutes > 1) {
          duration_str += 's';
        }
      }
      return duration_str.trim();
    } else {
      // Return just the number of minutes
      return minutes;
    }
  } else {
    // Convert milliseconds into hours, minutes, seconds
    let hours = Math.floor(duration_ms / (1000 * 60 * 60));
    duration_ms %= 1000 * 60 * 60;
    let minutes = Math.floor(duration_ms / (1000 * 60));
    duration_ms %= 1000 * 60;
    let seconds = Math.floor(duration_ms / 1000);

    // Prepare the output string
    let duration_str = '';
    if (hours > 0) {
      duration_str += hours + ' hour';
      if (hours > 1) {
        duration_str += 's';
      }
      duration_str += ' ';
    }
    if (minutes > 0) {
      duration_str += minutes + ' minute';
      if (minutes > 1) {
        duration_str += 's';
      }
      duration_str += ' ';
    }
    if (hours === 0 && minutes === 0) {
      duration_str += seconds + ' second';
      if (seconds !== 1) {
        duration_str += 's';
      }
    }

    return duration_str.trim();
  }
}
