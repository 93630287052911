import React, {createContext, useContext, useState} from 'react';

const NotificationContext = createContext();
const NotificationActionContext = createContext();

export const NotificationProvider = ({children}) => {
  const [notification, setNotification] = useState({
    title: '',
    message: '',
    type: ''
  });

  const showNotification = (messages, type, title = '') => {
    if (Array.isArray(messages)) {
      const message = messages[0];
      setNotification(() => ({
        title: title,
        message: message,
        type: type
      }));
      setTimeout(() => {
        setNotification(null);
      }, 12000);
    } else {
      setNotification(() => ({
        title: title,
        message: messages,
        type: type
      }));
      setTimeout(() => {
        setNotification(null);
      }, 12000); // Hide notification after 12 seconds
    }
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return (
    <NotificationContext.Provider value={{notification}}>
      <NotificationActionContext.Provider
        value={{showNotification, hideNotification}}
      >
        {children}
      </NotificationActionContext.Provider>
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const useNotificationActionContext = () => {
  return useContext(NotificationActionContext);
};

export default NotificationProvider;
