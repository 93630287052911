import textureformatter from '@myservice/helper/textureformatter';
import extractProfession from './extractProfession';

function getTextureCost(texture, orderTexture) {
  const textureObject = textureformatter(texture)?.innerObject || {};
  const textureInSqft = Object.entries(textureObject).map(([key, value]) => ({
    [key]: parseInt(value)
  }));
  return textureInSqft.find(
    (texture) => Object.keys(texture)[0] === orderTexture
  )?.[orderTexture];
}

function calculateWallCost(area, paintingCost = 0, underCoatCost = 0, order) {
  let cost = area * paintingCost;
  let formula = `${area} ft² x ${paintingCost} PKR`;
  const needUnderCover = ['no_paint', 'cracking', 'new_surface'].includes(
    order?.surface_condition
  );
  if (needUnderCover) {
    cost += area * underCoatCost;
    formula = `${area} ft² x ${paintingCost} PKR + undercoat ${area} ft² x ${underCoatCost} PKR`;
  }
  return {
    label: formula,
    value: Math.ceil(cost)
  };
}

function calculateWoodCost(area, wood_paintingCost, wood_polishingCost, order) {
  let cost = 0;
  let formula = '';
  if (order?.door_paint === 'polish') {
    cost = area * wood_polishingCost;
    formula = `${area} ft² x ${wood_polishingCost} PKR`;
  }
  if (order?.door_paint === 'paint') {
    cost = area * wood_paintingCost;
    formula = `${area} ft² x ${wood_paintingCost} PKR`;
  }

  return {
    label: formula,
    value: Math.ceil(cost)
  };
}

function filterCosts(cost) {
  const filtered = {};

  for (const category of ['labor', 'material']) {
    filtered[category] = {};

    for (const [key, value] of Object.entries(cost[category])) {
      if (value.value && value.value !== 0 && value.value !== '0') {
        filtered[category][key] = value;
      }
    }
  }

  if (cost.finalCost && cost.finalCost !== 0 && cost.finalCost !== '0') {
    filtered.finalCost = cost.finalCost;
  }

  return filtered;
}

export default function painterPriceCalculator(order, labor) {
  const painter = extractProfession(labor);

  const areas = {
    ceiling: order?.ceiling_area,
    exterior_wall: order?.exterior_wall_area,
    interior_wall: order?.interior_wall_area,
    door_trim: order?.door_trim_area,
    windows: order?.total_window_surface,
    cabinet: order?.total_cabinet_surface
  };

  const laborCharges = {
    undercoat: parseInt(painter?.under_coat_labor_charges),
    painting: parseInt(painter?.wall_painting_labor_charges),
    wood_painting: parseInt(painter?.wood_painting_labor_charges),
    wood_polishing: parseInt(painter?.wood_polishing_labor_charges),
    exterior: getTextureCost(
      painter?.texture_labor_charges,
      order?.exterior_wall_paint
    )
  };

  const materialCharges = {
    undercoat: parseInt(painter?.under_coat_with_material),
    painting: parseInt(painter?.wall_painting_with_material),
    wood_painting: parseInt(painter?.wood_painting_with_material),
    wood_polishing: parseInt(painter?.wood_polishing_with_material),
    exterior: getTextureCost(
      painter?.texture_with_material,
      order?.exterior_wall_paint
    )
  };

  const cost = {
    labor: {
      ceiling: calculateWallCost(
        areas.ceiling,
        laborCharges.painting,
        laborCharges.undercoat,
        order
      ),
      exterior: calculateWallCost(
        areas.exterior_wall,
        laborCharges.exterior,
        0,
        order
      ),
      interior: calculateWallCost(
        areas.interior_wall,
        laborCharges.painting,
        laborCharges.undercoat,
        order
      ),
      door_trim: calculateWoodCost(
        areas.door_trim,
        laborCharges.wood_painting,
        laborCharges.wood_polishing,
        order
      ),
      windows: calculateWoodCost(
        areas.windows,
        laborCharges.wood_painting,
        laborCharges.wood_polishing,
        order
      ),
      cabinet: calculateWoodCost(
        areas.cabinet,
        laborCharges.wood_painting,
        laborCharges.wood_polishing,
        order
      )
    },
    material: {
      ceiling: calculateWallCost(
        areas.ceiling,
        materialCharges.painting,
        materialCharges.undercoat,
        order
      ),
      exterior: calculateWallCost(
        areas.exterior_wall,
        materialCharges.exterior,
        0,
        order
      ),
      interior: calculateWallCost(
        areas.interior_wall,
        materialCharges.painting,
        materialCharges.undercoat,
        order
      ),
      door_trim: calculateWoodCost(
        areas.door_trim,
        materialCharges.wood_painting,
        materialCharges.wood_polishing,
        order
      ),
      windows: calculateWoodCost(
        areas.windows,
        materialCharges.wood_painting,
        materialCharges.wood_polishing,
        order
      ),
      cabinet: calculateWoodCost(
        areas.cabinet,
        materialCharges.wood_painting,
        materialCharges.wood_polishing,
        order
      )
    }
  };

  const laborCost = Object.values(cost.labor).reduce(
    (acc, val) => acc + val.value,
    0
  );
  const materialCost = Object.values(cost.material).reduce(
    (acc, val) => acc + val.value,
    0
  );

  cost['finalCost'] = painter?.material_include ? materialCost : laborCost;

  const filteredCosts = filterCosts(cost);

  return filteredCosts;
}
