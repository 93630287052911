export default function extractProfession(obj) {
  let targetObject = null;

  for (const key in obj) {
    const value = obj[key];
    if (
      Array.isArray(value) &&
      value.length === 1 &&
      typeof value[0] === 'object'
    ) {
      targetObject = value[0];
      break;
    }
  }

  return targetObject;
}
