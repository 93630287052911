export default function initialFormat(painter) {
  const initialForm = {
    building_type: painter?.building_type ?? '',
    building_type_other: painter?.painter?.building_type_other ?? '',
    surface_location: painter?.surface_location ?? '',
    surface_location_other: painter?.painter?.surface_location_other ?? '',
    bit_offer: painter?.bit_offer ?? '',
    bit_offer_unit: painter?.bit_offer_unit ?? '',
    bit_amount: painter?.bit_amount ?? '',
    interior_wall_area: painter?.interior_wall_area ?? '',
    interior_wall_unit: painter?.interior_wall_unit ?? 'ft²',
    ceiling_area: painter?.ceiling_area ?? '',
    ceiling_unit: painter?.ceiling_unit ?? 'ft²',

    exterior_wall_area: painter?.exterior_wall_area ?? '',
    exterior_wall_unit: painter?.exterior_wall_unit ?? 'ft²',
    exterior_wall_paint: painter?.exterior_wall_paint ?? '',

    door_trim_area: painter?.door_trim_area ?? '',
    door_trim_unit: painter?.door_trim_unit ?? 'ft²',
    door_paint: painter?.door_paint ?? '',
    address: painter?.address ?? '',

    total_window_surface: painter?.total_window_surface ?? '',
    window_surface_unit: painter?.window_surface_unit ?? 'ft²',
    total_cabinet_surface: painter?.total_cabinet_surface ?? '',
    cabinet_surface_unit: painter?.cabinet_surface_unit ?? 'ft²',
    num_rooms_spaces: painter?.num_rooms_spaces ?? '',
    paint_condition: painter?.paint_condition ?? '',
    labor_and_materials: painter?.labor_and_materials ?? 'labor_only',
    surface_condition: painter?.surface_condition ?? '',
    images: '',
    specific_concerns_requirements:
      painter?.specific_concerns_requirements ?? ''
  };

  return initialForm;
}
