export default function uptoTwoDecimal(num) {
  // Convert string to number if necessary
  if (typeof num === 'string') {
    num = parseFloat(num); // Use parseFloat instead of parseInt for decimal support
  }

  // Check if num is a valid number
  if (typeof num === 'number' && !isNaN(num)) {
    // Handle numbers larger than a million
    if (num > 1000000) {
      return Math.floor(num).toString(); // Return integer part as a string
    }

    // Format to two decimal places for numbers less than or equal to a million
    return num.toFixed(2).replace(/\.00$/, '');
  }

  // Return the original value if not a valid number
  return num;
}
