export default function determineSignUpField(data) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^03[0-9]{9}$/;

  if (emailRegex.test(data.emailOrPhone)) {
    data['email'] = data.emailOrPhone;
    data['phone_number'] = '';
    delete data.emailOrPhone; // Remove phone_number field if it exists
    return data;
  } else if (phoneRegex.test(data.emailOrPhone)) {
    data['phone_number'] = data.emailOrPhone;
    data['email'] = '';
    delete data.emailOrPhone; // Remove email field if it exists
    return data;
  } else {
    return data;
  }
}
