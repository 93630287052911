import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {
  AVAILABLE_DAYS_TIME,
  AVAILABLILITY,
  CEILING,
  DISHES,
  EDGES,
  STONES,
  SCHOOL,
  WORKER
} from 'apps/apis/allApis';
import React, {createContext, useContext, useState} from 'react';

const MiscellaneousContext = createContext();
const MiscellaneousActionContext = createContext();

export const MiscellaneousProvider = ({children}) => {
  const [miscellaneous, setMiscellaneous] = useState({
    misLoading: false,
    edges: [],
    stones: [],
    ceilings: [],
    dishes: [],
    workerAvailability: [],
    schools: [],
    school: [],
    retriveSchool: null
  });

  const {showNotification} = useNotificationMethod();

  const fetchData = async (url, method = 'GET', payload = null) => {
    try {
      setMiscellaneous((prev) => ({
        ...prev,
        misLoading: true
      }));
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });
      setMiscellaneous((prev) => ({
        ...prev,
        misLoading: false
      }));
      return response.data;
    } catch (error) {
      setMiscellaneous((prev) => ({
        ...prev,
        misLoading: false
      }));
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const getEdgeCutsDesigns = async () => {
    const edges = await fetchData(EDGES);
    setMiscellaneous((prev) => ({
      ...prev,
      edges: edges
    }));

    return edges;
  };

  const getStones = async () => {
    const stones = await fetchData(STONES);
    setMiscellaneous((prev) => ({
      ...prev,
      stones: stones
    }));

    return stones;
  };

  const getCeilings = async () => {
    const ceilings = await fetchData(CEILING);
    setMiscellaneous((prev) => ({
      ...prev,
      ceilings: ceilings
    }));

    return ceilings;
  };

  const getWorkerAvailability = async (id) => {
    const availability = await fetchData(`${AVAILABLILITY}${id}/`);
    setMiscellaneous((prev) => ({
      ...prev,
      workerAvailability: availability
    }));

    return availability;
  };

  const deleteWorkerTimeSlot = async (id) => {
    return await fetchData(`${WORKER}timeslot/${id}`, 'DELETE');
  };

  const getDishes = async (id) => {
    const dishes = await fetchData(`${DISHES}`);
    setMiscellaneous((prev) => ({
      ...prev,
      dishes: dishes
    }));

    return dishes;
  };

  const postWorkerSchdule = async (payload) => {
    return await fetchData(AVAILABLE_DAYS_TIME, 'POST', payload);
  };

  const postSchool = async (payload) => {
    const schoolRes = await fetchData(SCHOOL, 'POST', payload);
    getSchool(payload.user);
    return schoolRes;
  };

  const getSchools = async () => {
    const schoolRes = await fetchData(SCHOOL);
    setMiscellaneous((prev) => ({
      ...prev,
      schools: schoolRes
    }));

    return schoolRes;
  };

  const getSchool = async (id) => {
    if (id) {
      const schoolRes = await fetchData(`${SCHOOL}?user=${id}`);
      if (schoolRes?.length) {
        setMiscellaneous((prev) => ({
          ...prev,
          school: schoolRes
        }));
      }

      return schoolRes;
    }
  };

  const retrieveSchool = async (id) => {
    const schoolRetrive = await fetchData(`${SCHOOL}${id}`);
    setMiscellaneous((prev) => ({
      ...prev,
      retriveSchool: schoolRetrive
    }));

    return schoolRetrive;
  };

  return (
    <MiscellaneousContext.Provider value={{...miscellaneous}}>
      <MiscellaneousActionContext.Provider
        value={{
          getEdgeCutsDesigns,
          getStones,
          getCeilings,

          getWorkerAvailability,
          deleteWorkerTimeSlot,

          getDishes,
          postWorkerSchdule,

          postSchool,
          getSchool,
          getSchools,
          retrieveSchool
        }}
      >
        {children}
      </MiscellaneousActionContext.Provider>
    </MiscellaneousContext.Provider>
  );
};

export const useMiscellaneousContext = () => {
  return useContext(MiscellaneousContext);
};

export const useMiscellaneousActionContext = () => {
  return useContext(MiscellaneousActionContext);
};

export default MiscellaneousProvider;
