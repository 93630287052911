import {shortMonthNames} from 'configs';

export default function dateFormatter(dateString) {
  const date = new Date(dateString);
  const currentDate = new Date();

  // Check if the input date is the same as today
  if (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    // Return today's date along with the month
    const monthName = shortMonthNames[date.getMonth()];
    const day = date.getDate();
    return `Today, ${day} ${monthName}`;
  } else {
    // Return the formatted date
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const formattedDate = `${day} ${shortMonthNames[monthIndex]}`;

    // Exclude the year if it's the same as the current year
    if (year === currentDate.getFullYear()) {
      return formattedDate;
    } else {
      return `${formattedDate} ${year}`;
    }
  }
}
