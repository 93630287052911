export default function convertTo12HourFormat(time24) {
  if (!time24) {
    return null;
  }
  // Split the input time into hours, minutes, and seconds
  const [hours, minutes, seconds] = time24.split(':').map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hour from 24-hour to 12-hour format
  const hour12 = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format the minutes and seconds
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return the formatted time in 12-hour format
  return `${hour12}:${formattedMinutes}  ${period}`;
}
