import React, {useState} from 'react';
import styled from 'styled-components';

import SvgComp, {Dialog, DialogOverlay} from 'styles/common';
import {X} from 'assests/icons';
import {CustomButton} from '../Button/CustomButton';
import MobileDrag from './MobileDrag';

const DialogContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${({padding}) => padding ?? '1rem'};
`;

const FormWrapper = styled.div`
  p {
    font-size: 1.15rem;
    font-weight: 1rem;
  }
`;

const TitleWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${({theme}) => theme.bg[400]};
`;

const Title = styled.h5`
  font-size: 1.45rem;
  font-weight: 600;
  color: ${({theme}) => theme.clr[200]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3rem);
`;

const CloseDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999rem;
  width: 2.5rem;
  height: 2.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.bg[200]};
  }
`;

const CustomButtonWrapper = styled.div`
  border-top: 2px solid ${({theme}) => theme.bg[400]};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
`;

const DialogButton = ({handleClick, btnText}) => {
  return (
    <CustomButtonWrapper>
      <CustomButton
        onClick={() => handleClick()}
        text={btnText ?? 'Save'}
        sx={{
          padding: '0.75rem 2rem'
        }}
      />
    </CustomButtonWrapper>
  );
};

const ResponsiveDialogForm = ({
  setOpenDialog,
  openDialog,
  hideClose = false,
  padding,
  dialogBoxWidth,
  title,
  btnText,
  handleClick,
  sx,
  button,
  children
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  if (!openDialog) return null;
  if (isMobile) {
    return (
      <MobileDrag
        isMobile={isMobile}
        setIsMobile={setIsMobile}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        padding={padding}
        btnText={btnText}
        handleClick={handleClick}
        button={button}
        children={children}
      />
    );
  }

  if (!isMobile) {
    return (
      <DialogOverlay>
        <Dialog maxWidth={dialogBoxWidth ?? '72rem'} sx={sx}>
          <TitleWrapper>
            <Title>{title ?? ''}</Title>
            {!hideClose && (
              <CloseDialog onClick={() => setOpenDialog(false)}>
                <SvgComp
                  Icon={<X />}
                  size='1.4rem'
                  stroke={1}
                  fillColor={'transparent'}
                  strokeWidth={2}
                />
              </CloseDialog>
            )}
          </TitleWrapper>
          <DialogContent padding={padding}>
            <FormWrapper>{children}</FormWrapper>
          </DialogContent>
          {button ?? (
            <DialogButton btnText={btnText} handleClick={handleClick} />
          )}
        </Dialog>
      </DialogOverlay>
    );
  }
};

export default ResponsiveDialogForm;
