// Custom hook for infinite scrolling
import {useState, useCallback, useEffect, useRef} from 'react';

const useInfiniteScroll = (fetchFunction) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const lastItemRef = useRef(null);

  const fetchItems = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      // Artificial 2-second delay
      //   await new Promise((resolve) => setTimeout(resolve, 2000));

      const response = await fetchFunction();

      // If no response, stop loading and indicate no more items
      if (!response) {
        setLoading(false);
        setHasMore(false);
        return;
      }

      // Update hasMore based on the presence of a next page
      setHasMore(!!response.next);
    } catch (error) {
      console.error('Error fetching items:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [fetchFunction, loading]);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchItems();
        }
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 0.1
      }
    );

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [fetchItems, hasMore, loading]);

  // Initial fetch
  useEffect(() => {
    fetchItems();
  }, []);

  return {
    loading,
    hasMore,
    lastItemRef
  };
};

export default useInfiniteScroll;
