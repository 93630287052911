export default function formatObjectForDisplay(obj) {
  const formattedObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
      // Format key: remove underscores and capitalize each word
      const formattedKey = key
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      // Format value: if the value is not a string, convert it to string
      const value = typeof obj[key] === 'string' ? obj[key] : String(obj[key]);
      const formattedValue = value
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      formattedObject[formattedKey] = formattedValue;
    }
  }

  return formattedObject;
}
