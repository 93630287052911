import React from 'react';
import styled, {keyframes} from 'styled-components';

const Container = styled.div`
  background-color: transparent;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  padding: 1rem;
  font-size: 3rem;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const loadAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const ButtonLoader = styled.div`
  display: flex;
  gap: 0.25rem;

  > div {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #000;
    border-radius: 50%;
    animation: 1.2s infinite ease-in-out ${loadAnimation};
  }

  div:nth-child(1) {
    animation-delay: -0.32s;
  }

  div:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const MyComponent = ({show}) => {
  return (
    show && (
      <Container>
        <Button>
          <ButtonLoader>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </ButtonLoader>
        </Button>
      </Container>
    )
  );
};

export default MyComponent;
