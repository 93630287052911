export default function textureformatter(data) {
  try {
    if (typeof data !== 'undefined' && data !== null) {
      const textureLaborCharges = data;

      const innerObjectKey = Object.keys(textureLaborCharges).find(
        (key) => typeof textureLaborCharges[key] === 'object'
      );

      if (!innerObjectKey) {
        console.error('No inner object found');
        return [];
      }

      const innerObject = textureLaborCharges[innerObjectKey];

      const formattedPairs = Object.entries(innerObject).map(([key, value]) => {
        const formattedValue = capitalizeFirstLetter(key);
        const formattedValueWithSpace = formattedValue.replace(/_/g, ' ');
        return {[`${formattedValueWithSpace}`]: value};
      });

      return {formattedPairs, innerObject};
    } else {
      console.error('Data is undefined or null');
      return []; // Return empty array if data is undefined or null
    }
  } catch (error) {
    console.error('Error parsing texture labor charges:', error);
    return []; // Return empty array if there's an error
  }
}

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
