import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {
  WORKER_CEILING,
  PAINTER_PACKAGE,
  WORKER_STONE,
  WORKER_OFFERING_DISHS,
  TOUR_GUIDE_PACKAGE,
  ROOMS
} from 'apps/apis/allApis';
import React, {createContext, useContext, useState} from 'react';

const PackageContext = createContext();
const PackageContextAction = createContext();

export const PackageProvider = ({children}) => {
  const [packages, setPackages] = useState({
    packLoading: false,
    painterPackages: [],
    stoneTypePackages: [],
    chefPackages: [],
    ceilingTypePackages: [],
    tourGuidePackages: [],
    hotelRooms: [],
    allRooms: [],
    tourGuidePackage: null,
    painterPackage: null,
    chefPackage: null
  });

  const {showNotification} = useNotificationMethod();

  const fetchData = async (url, method = 'GET', payload = null, isFormData) => {
    try {
      setPackages((prev) => ({
        ...prev,
        packLoading: true
      }));
      const response = await axiosInstance({
        method,
        url,
        data: payload,
        headers: isFormData ? {'Content-Type': 'multipart/form-data'} : {}
      });

      setPackages((prev) => ({
        ...prev,
        packLoading: false
      }));
      return response.data;
    } catch (error) {
      setPackages((prev) => ({
        ...prev,
        packLoading: false
      }));
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const postPainterPackage = async (payload) => {
    const response = await fetchData(PAINTER_PACKAGE, 'POST', payload);
    if (response?.id) {
      showNotification('New package Added Succesfully', 'success');
      getPainterPackage(payload.worker);
    }
    return response;
  };

  const patchPainterPackage = async (payload) => {
    const response = await fetchData(
      `${PAINTER_PACKAGE}${payload.id}/`,
      'PATCH',
      payload
    );

    if (response?.id) {
      showNotification('Package Updated Succesfully', 'success');
      getPainterPackage(payload.worker);
    }
    return response;
  };

  const getPainterPackage = async (uuid) => {
    const response = await fetchData(`${PAINTER_PACKAGE}?worker=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      painterPackages: response
    }));
    return response;
  };

  const retrievePainterPackage = async (uuid) => {
    const response = await fetchData(`${PAINTER_PACKAGE}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      painterPackage: response
    }));
    return response;
  };

  const delPainterPackage = async (payload) => {
    const response = await fetchData(
      `${PAINTER_PACKAGE}${payload.id}/`,
      'DELETE'
    );
    getPainterPackage(payload.worker);
    return response;
  };

  const getStoneType = async (uuid) => {
    const response = await fetchData(`${WORKER_STONE}?installer=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      stoneTypePackages: response
    }));
    return response;
  };

  const postStoneType = async (payload) => {
    const response = await fetchData(WORKER_STONE, 'POST', payload);
    getStoneType(payload.installer);
    return response;
  };

  const patchStoneType = async (payload) => {
    const response = await fetchData(
      `${WORKER_STONE}${payload.id}/`,
      'PATCH',
      payload
    );
    getStoneType(payload.installer);
    return response;
  };

  const delStoneType = async (payload) => {
    const response = await fetchData(`${WORKER_STONE}${payload.id}/`, 'DELETE');
    getStoneType(payload.installer);
    return response;
  };

  const postChefOffer = async (payload) => {
    const resp = await fetchData(WORKER_OFFERING_DISHS, 'POST', payload);
    getChefOffer(payload.chef);
    return resp;
  };

  const patchChefOffer = async (payload) => {
    const response = await fetchData(
      `${WORKER_OFFERING_DISHS}${payload.id}/`,
      'PATCH',
      payload
    );
    getChefOffer(payload.id);
    return response;
  };

  const getChefOffer = async (uuid) => {
    const response = await fetchData(`${WORKER_OFFERING_DISHS}?chef=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      chefPackages: response
    }));
    return response;
  };

  const retrieveChefOffer = async (uuid) => {
    const response = await fetchData(`${WORKER_OFFERING_DISHS}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      chefPackage: response
    }));
    return response;
  };

  const delChefOffer = async (payload) => {
    const response = await fetchData(
      `${WORKER_OFFERING_DISHS}${payload.id}/`,
      'DELETE'
    );
    getChefOffer(payload.chef);
    return response;
  };

  const postNewRoom = async (payload) => {
    return await fetchData(ROOMS, 'POST', payload, true);
  };

  const editRoomDetails = async (payload, UUID) => {
    return await fetchData(`${ROOMS}${UUID}/`, 'PATCH', payload, true);
  };

  const delRoomImage = async (ROOM_UUID, IMG_UUID) => {
    try {
      const response = await axiosInstance.delete(
        `${ROOMS}${ROOM_UUID}/delete-image/${IMG_UUID}`
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const getHotelRooms = async (UUID) => {
    const rooms = await fetchData(`${ROOMS}rooms/${UUID}`);

    setPackages((prev) => ({
      ...prev,
      hotelRooms: rooms
    }));
    return rooms;
  };

  const getAllRooms = async () => {
    const allRooms = await fetchData(ROOMS);
    setPackages((prev) => ({
      ...prev,
      allRooms: allRooms
    }));
    return allRooms;
  };

  const getRoomDetails = async (UUID) => {
    return await fetchData(`${ROOMS}${UUID}`);
  };

  const postCeilingType = async (payload) => {
    return await fetchData(WORKER_CEILING, 'POST', payload, true);
  };

  const patchCeilingType = async (payload) => {
    const response = await fetchData(
      `${WORKER_CEILING}${payload.id}/`,
      'PATCH',
      payload
    );
    getCeilingType(payload.installer);
    return response;
  };

  const getCeilingType = async (uuid) => {
    const response = await fetchData(`${WORKER_CEILING}?installer=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      ceilingTypePackages: response
    }));
    return response;
  };

  const delCeilingType = async (payload) => {
    const response = await fetchData(
      `${WORKER_CEILING}${payload.id}/`,
      'DELETE'
    );
    getCeilingType(payload.installer);
    return response;
  };

  const postTourGuidePackage = async (payload) => {
    const response = await fetchData(TOUR_GUIDE_PACKAGE, 'POST', payload);

    if (response?.id) {
      getTourGuidePackage(payload['worker']);
      return response;
    }
  };

  const patchTourGuidePackage = async (payload) => {
    const response = await fetchData(
      `${TOUR_GUIDE_PACKAGE}${payload.id}/`,
      'PATCH',
      payload
    );
    getTourGuidePackage(payload.guide_name);
    return response;
  };

  const getTourGuidePackage = async (uuid) => {
    const response = await fetchData(`${TOUR_GUIDE_PACKAGE}?worker=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      tourGuidePackages: response
    }));
    return response;
  };

  const retrieveTourGuidePackage = async (uuid) => {
    const response = await fetchData(`${TOUR_GUIDE_PACKAGE}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      tourGuidePackage: response
    }));
    return response;
  };

  const delTourGuidePackage = async (payload) => {
    const response = await fetchData(
      `${TOUR_GUIDE_PACKAGE}${payload.id}/`,
      'DELETE'
    );
    getTourGuidePackage(payload.guide_name);
    return response;
  };

  return (
    <PackageContext.Provider value={{...packages}}>
      <PackageContextAction.Provider
        value={{
          getCeilingType,
          postCeilingType,
          patchCeilingType,
          delCeilingType,

          getStoneType,
          postStoneType,
          patchStoneType,
          delStoneType,

          postPainterPackage,
          patchPainterPackage,
          getPainterPackage,
          delPainterPackage,
          retrievePainterPackage,

          getChefOffer,
          retrieveChefOffer,
          postChefOffer,
          patchChefOffer,
          delChefOffer,

          postTourGuidePackage,
          patchTourGuidePackage,
          delTourGuidePackage,
          getTourGuidePackage,
          retrieveTourGuidePackage,

          postNewRoom,
          getHotelRooms,
          getRoomDetails,
          getAllRooms,
          editRoomDetails,
          delRoomImage
        }}
      >
        {children}
      </PackageContextAction.Provider>
    </PackageContext.Provider>
  );
};

export const usePackageContext = () => {
  return useContext(PackageContext);
};

export const usePackageContextAction = () => {
  return useContext(PackageContextAction);
};

export default PackageProvider;
