import {
  useAccommodationContext,
  useAccommodationActions
} from 'apps/accommodation/AccommodationProvider';

export const useAccommodation = () => {
  const {accommodationState, featureAccommodation, loading} =
    useAccommodationContext();
  return {
    accommodationState,
    featureAccommodation,
    loading
  };
};

export const useAccommodationMethod = () => {
  const {getAccommodations, getFeatureAccommodations} =
    useAccommodationActions();
  return {getAccommodations, getFeatureAccommodations};
};
