import {
  usePackageContext,
  usePackageContextAction
} from 'apps/packages/PackageProvider';

export const usePackage = () => {
  const {
    packLoading,
    painterPackages,
    painterPackage,
    chefPackages,
    chefPackage,
    stoneTypePackages,
    ceilingTypePackages,
    tourGuidePackages,
    tourGuidePackage,
    hotelRooms,
    allRooms
  } = usePackageContext();
  return {
    packLoading,

    painterPackages,
    painterPackage,

    stoneTypePackages,

    chefPackages,
    chefPackage,

    ceilingTypePackages,

    tourGuidePackages,
    tourGuidePackage,

    hotelRooms,
    allRooms
  };
};

export const usePackageMethod = () => {
  const {
    postCeilingType,
    patchCeilingType,
    getCeilingType,
    delCeilingType,

    getStoneType,
    postStoneType,
    delStoneType,
    patchStoneType,

    postPainterPackage,
    patchPainterPackage,
    delPainterPackage,
    getPainterPackage,
    retrievePainterPackage,

    postTourGuidePackage,
    patchTourGuidePackage,
    delTourGuidePackage,
    getTourGuidePackage,
    retrieveTourGuidePackage,

    getChefOffer,
    retrieveChefOffer,
    postChefOffer,
    patchChefOffer,
    delChefOffer,

    postNewRoom,
    getHotelRooms,
    getRoomDetails,
    getAllRooms,
    editRoomDetails,
    delRoomImage
  } = usePackageContextAction();
  return {
    postCeilingType,
    patchCeilingType,
    getCeilingType,
    delCeilingType,

    getStoneType,
    postStoneType,
    delStoneType,
    patchStoneType,

    postPainterPackage,
    patchPainterPackage,
    getPainterPackage,
    delPainterPackage,
    retrievePainterPackage,

    postTourGuidePackage,
    patchTourGuidePackage,
    delTourGuidePackage,
    getTourGuidePackage,
    retrieveTourGuidePackage,

    getChefOffer,
    retrieveChefOffer,
    postChefOffer,
    patchChefOffer,
    delChefOffer,

    postNewRoom,
    getHotelRooms,
    getRoomDetails,
    getAllRooms,
    editRoomDetails,
    delRoomImage
  };
};
