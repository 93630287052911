const safeJsonParse = (value) => {
  try {
    return value ? JSON.parse(decodeURIComponent(value)) : null;
  } catch (e) {
    console.error('Invalid JSON:', value, e);
    return null;
  }
};

export default safeJsonParse;
