import {
  usePainterContext,
  usePainterActionContext
} from 'apps/services/PainterProvider';

import painterInitailForm from '../helper/painerOrderInitialForm';

export const usePainter = () => {
  const {
    paintingQueries,
    orderLoading,
    painterLoading,
    allPaintingQueries,
    bids,
    selfBids
  } = usePainterContext();
  return {
    allPaintingQueries,
    paintingQueries,
    orderLoading,
    painterLoading,
    bids,
    selfBids,
    initialForm: painterInitailForm(paintingQueries)
  };
};

export const usePainterMethod = () => {
  const {
    getPaintingQueryById,
    createPainterQuestions,
    patchPainter,
    getPainerQueryById,
    addPaintingBid,
    offerBid,
    getAllBids,
    getSelfBids,
    updateBidder,
    updateBid
  } = usePainterActionContext();

  return {
    getPaintingQueryById,
    createPainterQuestions,
    patchPainter,
    getPainerQueryById,
    offerBid,
    addPaintingBid,
    getAllBids,
    getSelfBids,
    updateBidder,
    updateBid
  };
};
