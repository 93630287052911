import {
  useArticleActionContext,
  useArticleContext
} from 'apps/articles/ArticleProvider';

export const useArticle = () => {
  const {articles, article, articlesLoading} = useArticleContext();
  return {articles, article, articlesLoading};
};

export const useArticleMethod = () => {
  const {getAllArticles, getAnArticle} = useArticleActionContext();
  return {getAllArticles, getAnArticle};
};
