import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AVAILABLE_SERVICES, SEARCH_SERVICES} from '../apis/allApis';
import axiosInstance from '../apis';
import {useAuthUser} from '@myservice/hooks/AuthUser';

const ServicesContext = createContext();
const ServicesActionsContext = createContext();

const Services = ({children}) => {
  const [services, setServices] = useState({
    availableServices: [],
    queryResult: []
  });
  const {isAuthenticated} = useAuthUser();

  const getAvailableServices = async () => {
    try {
      const {data} = await axiosInstance.get(AVAILABLE_SERVICES);
      setServices({
        ...services,
        availableServices: [...data]
      });
    } catch (error) {
      console.error(error.response?.data);
    }
  };

  const searchServices = async (param) => {
    try {
      const {data} = await axiosInstance.get(`${SEARCH_SERVICES}?Q=${param}`);
      if (param) {
        setServices({
          ...services,
          queryResult: [...data]
        });
      }
    } catch (error) {
      console.error(error.response.data);
    }
  };

  useEffect(() => {
    getAvailableServices();
  }, [isAuthenticated]);

  return (
    <ServicesContext.Provider
      value={{
        ...services
      }}
    >
      <ServicesActionsContext.Provider
        value={{
          getAvailableServices,
          searchServices,
          setServices
        }}
      >
        {children}
      </ServicesActionsContext.Provider>
    </ServicesContext.Provider>
  );
};

export const useServices = () => useContext(ServicesContext);

export const useServicesActions = () => useContext(ServicesActionsContext);

export default Services;

Services.propTypes = {
  children: PropTypes.node.isRequired
};
