import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';

import extractError from '@myservice/helper/extractError';
import {useAuthUser, useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {
  ALL_CS_ROOMs,
  CREATE_PRIVATE_ROOM,
  CREATECSCHAT,
  USER_CS_ROOM,
  USER_PRIVATE_CHATS,
  SEARCH_CHAT,
  AVAILABLE_WORKERs,
  AVAILABLE_CHAT_USERs,
  SOCKET_URL
} from 'apps/apis/allApis';

import {
  computeSharedSecret,
  deriveKey,
  encryptMessage,
  decryptMessage,
  stringToPublicKey
} from '@myservice/utility';

const ChatContext = createContext();
const ChatActionContext = createContext();

export const ChatProvider = ({children}) => {
  const [ws, setWs] = useState(null);
  const [loadingChat, setLoadingChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatWorker, setChatWorker] = useState([]);
  const [chatUser, setChatUser] = useState([]);
  const [userTyping, setUserTyping] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [conversation, setConversation] = useState(null);

  const {isAuthenticated} = useAuthUser();

  const [privateKey, setPrivateKey] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [sharedSecret, setSharedSecret] = useState(null);
  const [roomKey, setRoomKey] = useState(null);
  const [inputMessage, setInputMessage] = useState('');

  const {showNotification} = useNotificationMethod();

  const fetchChat = async (url, method = 'GET', payload = null) => {
    setLoadingChat(true);
    try {
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });

      return response?.data;
    } catch (error) {
      showNotification(
        extractError(error.response.data) || 'Unknown Error in Chat'
      );

      return null;
    } finally {
      setLoadingChat(false);
    }
  };

  const searchWorker = async (searchTerm) => {
    const resp = await fetchChat(
      `${AVAILABLE_WORKERs}?query=${encodeURIComponent(searchTerm)}`
    );

    setChatWorker(resp);
    return;
  };

  const searchChatUsers = async (searchTerm) => {
    const resp = await fetchChat(
      `${AVAILABLE_CHAT_USERs}?query=${encodeURIComponent(searchTerm)}`
    );
    setChatUser(resp);
    return;
  };

  const searchChat = async (roomType, searchTerm) => {
    return await fetchChat(
      `${SEARCH_CHAT}?type=${roomType}&name=${encodeURIComponent(searchTerm)}`
    );
  };

  // Create a New Private Chat Room
  const createPrivateChat = async (profile) => {
    return await fetchChat(CREATE_PRIVATE_ROOM, 'POST', {user_id: profile?.id});
  };

  // Join Customer Service Chat Room
  const joinCsChat = async (room) => {
    return await fetchChat(
      `communicate/rooms/${room?.id}/join-cs-chat/`,
      'POST'
    );
  };

  // Create a New Customer Support Chat
  const createCustomerSupportChat = async () => {
    return await fetchChat(CREATECSCHAT, 'POST');
  };

  // Retrieve Private Chat Rooms
  const getUserPrivateRooms = async () => {
    return fetchChat(USER_PRIVATE_CHATS);
  };

  // Retrieve Customer Service Chat Rooms
  const getUserCustomerServiceRooms = async () => {
    return await fetchChat(USER_CS_ROOM);
  };

  // Retrieve All Customer Service Chat Rooms
  const getAllCustomerServiceChats = async () => {
    return await fetchChat(ALL_CS_ROOMs);
  };

  // Retrieve All Messages for a Specific Chat Room
  const getMessagesForChatRoom = async (room) => {
    if (room) {
      return await fetchChat(`communicate/rooms/${room?.id}/messages/`);
      //     const decryptedMessages = await Promise.all(
      //       response.data.map(async (msg) => ({
      //         ...msg,
      //         content: await decryptMessage(roomKey, msg.content)
      //       }))
      //     );
      //     setMessages(decryptedMessages);
    }
  };

  const connectCSWebSocket = (roomId) => {
    // console.log('called connectCSWebSocket');
    const token = localStorage.getItem('access');
    const url = `${SOCKET_URL}/ws/customer-service/${roomId}/`;
    const fullUrl = `${url}?token=${token}`;
    const socket = new WebSocket(fullUrl);

    socket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleMessage(data);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
    };

    setWs(socket);
  };

  const handleMessage = (data) => {
    if (data.type === 'chat_message') {
      setMessages((prev) => [
        ...prev,
        {user: data.user, message: data.message, timestamp: data.timestamp}
      ]);
    } else if (data.type === 'typing') {
      setUserTyping(data.user);
    }
  };

  const sendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({type: 'chat_message', message}));
    }
  };

  const notifyTyping = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({type: 'typing'}));
    }
  };

  const connectPrivateWebSocket = (userId) => {
    console.log('connectPrivateWebSocket');
    const token = localStorage.getItem('access');
    const url = `${SOCKET_URL}/ws/private-chat/${userId}/`;
    const fullUrl = `${url}?token=${token}`;

    const socket = new WebSocket(fullUrl);

    socket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
      console.log(publicKey);
      if (publicKey) {
        socket.send(
          JSON.stringify({
            type: 'public_key',
            public_key: JSON.stringify(publicKey)
          })
        );
      } else {
        console.error('Public key not available');
      }
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handlePrivateMessage(data);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
    };

    setWs(socket);
  };

  const handlePrivateMessage = useCallback(
    async (data) => {
      if (data.type === 'chat_message') {
        if (roomKey) {
          try {
            const decryptedMessage = await decryptMessage(
              roomKey,
              data.message
            );
            setMessages((prev) => [
              ...prev,
              {
                user: data.user,
                message: decryptedMessage,
                timestamp: data.timestamp
              }
            ]);
          } catch (error) {
            console.error('Failed to decrypt message:', error);
          }
        } else {
          console.warn('Room key not established yet');
        }
      } else if (data.type === 'typing') {
        setUserTyping(data.user);
      } else if (data.type === 'public_key') {
        const otherPublicKey = await stringToPublicKey(data.public_key);
        try {
          const secret = await computeSharedSecret(privateKey, otherPublicKey);
          const key = await deriveKey(secret, conversation.salt);
          setRoomKey(key);
        } catch (error) {
          console.error('Failed to establish room key:', error);
        }
      }
    },
    [roomKey, privateKey, conversation]
  );

  const sendPrivateMessage = useCallback(async () => {
    if (
      ws &&
      ws.readyState === WebSocket.OPEN &&
      roomKey &&
      inputMessage.trim()
    ) {
      try {
        const encryptedMessage = await encryptMessage(roomKey, inputMessage);
        ws.send(
          JSON.stringify({type: 'chat_message', message: encryptedMessage})
        );
        setInputMessage('');
      } catch (error) {
        console.error('Failed to encrypt and send message:', error);
      }
    }
  }, [ws, roomKey, inputMessage]);

  const notifyPrivateTyping = useCallback(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({type: 'typing'}));
    }
  }, [ws]);

  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  useEffect(() => {
    const fetchWorker = async () => {
      await searchChatUsers('');
    };

    if (isAuthenticated) {
      fetchWorker();
    }
  }, [isAuthenticated]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        userTyping,
        isConnected,
        chatWorker,
        chatUser,
        loadingChat,
        conversation,
        privateKey,
        publicKey
      }}
    >
      <ChatActionContext.Provider
        value={{
          searchChat,
          joinCsChat,
          sendMessage,
          notifyTyping,
          setPrivateKey,
          setPublicKey,
          searchWorker,
          setConversation,
          searchChatUsers,
          createPrivateChat,
          connectCSWebSocket,
          sendPrivateMessage,
          notifyPrivateTyping,
          getUserPrivateRooms,
          connectPrivateWebSocket,
          getMessagesForChatRoom,
          createCustomerSupportChat,
          getAllCustomerServiceChats,
          getUserCustomerServiceRooms
        }}
      >
        {children}
      </ChatActionContext.Provider>
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
export const useChatActionContext = () => useContext(ChatActionContext);

export default ChatProvider;
