import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RotateIcon from 'styles/Loader';

export const CustomButton = ({
  bg,
  clr,
  borderClr,
  hoverBg,
  boxShadow,
  text,
  onClick,
  disabled,
  sx,
  type,
  icon,
  iconSx,
  loadingIcon,
  isLoading
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      $boxShadow={boxShadow}
      $bg={bg}
      $clr={clr}
      $borderClr={borderClr}
      $hoverBg={hoverBg}
      $text={text}
      $sx={sx}
      $iconSx={iconSx}
      $loading={isLoading}
      type={type}
      disabled={disabled}
    >
      <span>{loadingIcon ? <RotateIcon /> : text}</span>
      {icon && !loadingIcon && icon}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  display: flex;
  max-width: 42rem;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.3rem;
  background-color: ${({$bg, disabled, theme}) =>
    $bg && !disabled
      ? $bg
      : disabled
        ? theme.bg[400]
        : theme.secondary.default};
  color: ${({$clr}) => ($clr ? $clr : '#fff')};
  font-weight: 400;
  font-size: 1rem;
  font-family: ${({theme}) => theme.font};
  border: 1.4px solid
    ${({$borderClr}) => ($borderClr ? $borderClr : 'transparent')};
  box-shadow: ${({$boxShadow}) =>
    $boxShadow ? '0px 1px 2px 0px #1018280d' : ''};

  > span {
    margin-inline: ${({$text}) => ($text ? '1.3rem' : '')};
  }

  img {
    width: 1.8rem;
    ${({$iconSx}) => $iconSx}
  }

  &:hover {
    cursor: ${({$loading, disabled}) =>
      $loading && !disabled
        ? 'progress'
        : disabled && !$loading
          ? 'not-allowed'
          : 'pointer'};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  ${({$sx}) => $sx}
`;

CustomButton.propTypes = {
  bg: PropTypes.string,
  clr: PropTypes.string,
  borderClr: PropTypes.any,
  hoverBg: PropTypes.any,
  boxShadow: PropTypes.any,
  text: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.any,
  icon: PropTypes.any,
  iconSx: PropTypes.object
};
