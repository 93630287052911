import {
  usePaymentContext,
  usePaymentActionContext
} from 'apps/payments/PaymentProvider';

export const usePayment = () => {
  const {payLoading, transactionData, transactionUrl} = usePaymentContext();
  return {
    payLoading,
    transactionData,
    transactionUrl
  };
};

export const usePaymentMethod = () => {
  const {processPayFastPayment} = usePaymentActionContext();
  return {processPayFastPayment};
};
