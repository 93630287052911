import React from 'react';
import styled, {keyframes} from 'styled-components';
import SvgComp from '../styles/common';
import {Loading} from '../assests/icons';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: ${rotateAnimation} 2s linear infinite;
`;

const RotateIcon = () => {
  return (
    <>
      <RotatingSvgContainer>
        <SvgComp size={'1.5rem'} Icon={<Loading />} />
      </RotatingSvgContainer>
    </>
  );
};

export default RotateIcon;
