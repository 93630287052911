import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
	*,
	*::before,
	*::after {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}
	html {
		font-family:  "Be Vietnam Pro", 'Albert Sans', sans-serif;
		background-color: '#e3e6e6';
		scroll-behavior: smooth;


	}
	body {
		font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		background-color: '#e3e6e6'
	}

	
	@media only screen and (max-width: 26.499em) {
		h1 {
			
			font-size: 2.25rem;
			line-height: 2.35rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h2 {
			font-size: 2rem;
			line-height: 2.24rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h3 {
			font-size: 1.75rem;
			line-height: 1.92rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h4 {
			font-size: 1.5rem;
			line-height: 1.88rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h5 {
			font-size: 1.3125rem;
			line-height: 1.6875rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		h6 {
			font-size: 0.9125rem;
			line-height: 1.0875rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		p {
			font-size: 1rem;
			line-height: 1.5rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
	}
	@media only screen and (min-width: 26.5em) and (max-width: 45.749em) {
		h1 {
  	  		font-size: 3.2425rem;
  	 	 	line-height: 4.2125rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h2 {
			font-size: 2.59rem;
			line-height: 3.45rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h3 {
			font-size: 2.705rem;
			line-height: 2.76rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h4 {
			font-size: 1.625rem;
			line-height: 2.18rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h5 {
			font-size: 1.3438rem;
			line-height: 1.75rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		h6 {
			font-size: 0.9538rem;
			line-height: 1.25rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		p {
			font-size: 1rem;
			line-height: 1.59rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
			}
		}
	@media only screen and (min-width: 45.75em) and (max-width: 63.99999em) {
		h1 {
			font-size: 3.25rem;
			line-height: 3.51rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h2 {
			font-size: 2.5938rem;
			line-height: 2.8rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h3 {
			font-size: 2.0752rem;
			line-height: 2.4625rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h4 {
			font-size: 1.625rem;
			line-height: 2rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h5 {
			font-size: 1.3438rem;
			line-height: 1.75rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		h6 {
			font-size: 1.0438rem;
			line-height: 1.25rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		p {
			font-size: 1rem;
			line-height:1.2938rem;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

	}
	@media only screen and (min-width: 64em){
		h1 {
			font-size: 4.05rem;
			line-height: 5.4rem;
			font-weight: 600;

			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h2 {
			font-size: 3.45rem;
			line-height: 3.512rem;
			font-weight: 600;

			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		h3 {
			font-size: 2.993rem;
			line-height: 3.25rem;
			font-weight: 600;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		
		h4 {
			font-size: 2.025rem;
			line-height: 2.2062rem;
			font-weight: 600;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		h5 {
			font-size: 1.7875rem;
			line-height: 1.71rem;
			font-weight: 600;
			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}

		h6 {
			font-size: 1.2875rem;
			line-height: 1.21rem;
			font-weight: 600;

			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
		p {
			font-size: 1rem;
			line-height: 1.39rem;

			font-family: "Be Vietnam Pro", 'Albert Sans', sans-serif;
		}
	}
	`;

export default GlobalStyle;
