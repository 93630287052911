import convertDateToReadable from './convertDateToReadable';

export default function formatSchoolDropdown(schools) {
  let dropdownList = [
    {
      value: '',
      default: true,
      label: 'Choose an School'
    }
  ];
  for (let school of schools) {
    let address = {
      label: school.school_name,
      value: school.id
    };
    dropdownList.push(address);
  }
  return dropdownList;
}

export function schoolCheckBox(schools) {
  let checkBox = [];
  for (let school of schools) {
    let address = {
      label: school.school_name,
      value: school.id,
      description: `${school.degree} in ${school.field_of_study} from ${convertDateToReadable(school.enrollment)} to ${convertDateToReadable(school.graduation) || 'Present'}`
    };
    checkBox.push(address);
  }
  return checkBox;
}
