export default function formatDatetime(datetimeStr) {
  if (!datetimeStr) {
    return null;
  }

  const dt = new Date(datetimeStr);
  const currentYear = new Date().getFullYear();

  if (dt.getFullYear() === currentYear) {
    return dt.toLocaleString('default', {month: 'short', day: '2-digit'});
  } else {
    return dt.toLocaleString('default', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
  }
}

export function chatDatetime(datetimeStr) {
  if (!datetimeStr) {
    return null;
  }

  const dt = new Date(datetimeStr);
  const now = new Date();
  const today = now.toDateString();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const dateString = dt.toDateString();

  if (dateString === today) {
    return dt.toLocaleString('default', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  } else if (dateString === yesterday.toDateString()) {
    return 'Yesterday';
  } else if (dt.getFullYear() === now.getFullYear()) {
    return dt.toLocaleString('default', {month: 'short', day: '2-digit'});
  } else {
    return dt.toLocaleString('default', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
  }
}
