import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../apis';
import {TOUR_GUIDE_QUERY} from '../apis/allApis';
import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';

const TourGuideContext = createContext();
const TourGuideActionContext = createContext();

const TourGuideProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();
  const [tourGuideLoading, setTourGuideLoading] = useState(false);

  const [tourGuide, setTourGuide] = useState({
    tourGuideQueries: JSON.parse(localStorage.getItem('TGId')) ?? null,
    paintingQueriesId: null
  });

  const fetchTourGuide = async (url, method = 'GET', payload = null) => {
    try {
      setTourGuideLoading(true);
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });
      setTourGuideLoading(false);
      return response.data;
    } catch (error) {
      setTourGuideLoading(false);
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const getTourGuideById = async (id) => {
    const url = TOUR_GUIDE_QUERY + `${id}/`;
    const {data} = await fetchTourGuide(url);
    setTourGuide(() => ({
      ...tourGuide,
      paintingQueriesId: data
    }));
  };

  const patchTourGuide = async (queryPayload) => {
    const data = await fetchTourGuide(
      `${TOUR_GUIDE_QUERY}${tourGuide?.tourGuideQueries?.id}/`,
      'PUT',
      queryPayload
    );

    if (data?.response?.data?.detail === 'Not found.') {
      localStorage.removeItem('PId');
      localStorage.removeItem('TGId');
    }

    return data;
  };

  const createTourGuide = async (queryPayload) => {
    if (tourGuide?.tourGuideQueries?.id) {
      return await patchTourGuide(queryPayload);
    }
    return await fetchTourGuide(TOUR_GUIDE_QUERY, 'POST', queryPayload);
  };

  return (
    <TourGuideContext.Provider value={{...tourGuide, tourGuideLoading}}>
      <TourGuideActionContext.Provider
        value={{
          createTourGuide,
          patchTourGuide,
          getTourGuideById
        }}
      >
        {children}
      </TourGuideActionContext.Provider>
    </TourGuideContext.Provider>
  );
};

export const useTourGuideContext = () => useContext(TourGuideContext);
export const usePainterActionContext = () => useContext(TourGuideActionContext);

export default TourGuideProvider;

TourGuideProvider.propTypes = {
  children: PropTypes.node.isRequired
};
