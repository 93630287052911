import dashAdder from './dashAdder';

export default function findCommonProperties(array1, array2, key1, key2) {
  // Check if inputs are valid
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    console.warn(
      'findCommonProperties: One or both inputs are not arrays. Returning an empty array.'
    );
    return [];
  }

  if (typeof key1 !== 'string' || typeof key2 !== 'string') {
    console.warn(
      'findCommonProperties: One or both keys are not strings. Returning an empty array.'
    );
    return [];
  }

  // Create a map of array2 elements based on key2 values
  const array2Map = array2.reduce((map, obj) => {
    if (obj && typeof obj === 'object' && key2 in obj) {
      const key2Value = dashAdder(obj[key2]);
      if (key2Value !== undefined) {
        map[key2Value] = obj;
      }
    }
    return map;
  }, {});

  // Initialize combined array
  const combinedArray = [];

  // Merge array1 elements with corresponding array2 elements based on key1 and key2 values
  array1.forEach((item) => {
    if (item && typeof item === 'object' && key1 in item) {
      const key1Value = dashAdder(item[key1]);
      if (key1Value !== undefined && key1Value in array2Map) {
        const mergedItem = {
          ...item,
          ...array2Map[key1Value]
        };
        combinedArray.push(mergedItem);
      }
    }
  });

  return combinedArray;
}
