function calculateDateDifference(createdDate, endDate = new Date()) {
  if (typeof createdDate === 'string') {
    createdDate = new Date(createdDate);
  }
  if (typeof endDate === 'string') {
    endDate = new Date(endDate);
  }

  const createdDateObj = new Date(createdDate);
  const endDateObj = new Date(endDate);

  const deltaTime = endDateObj - createdDateObj;
  const deltaDays = Math.floor(deltaTime / (1000 * 60 * 60 * 24));

  const years = Math.floor(deltaDays / 365);
  const months = Math.floor(deltaDays / 30);
  const days = deltaDays;

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''}`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''}`;
  } else {
    return `${days + 1} day${days > 1 ? 's' : ''}`;
  }
}
export default calculateDateDifference;
