import {
  useAddresActionsContext,
  useAddressContext
} from 'apps/address/AddressProvider';
import {addressOfferDropDown} from '../helper';

export const useAddress = () => {
  const {allAddress, count, retrivedAddress, newCreatedAddress} =
    useAddressContext();
  return {
    newCreatedAddress,
    retrivedAddress,
    allAddress,
    count,
    addressOfferDropDown: addressOfferDropDown(allAddress)
  };
};

export const useAddressMethod = () => {
  const {
    getAllAddress,
    retriveAddress,
    createAddress,
    patchAddress,
    destroyAddress
  } = useAddresActionsContext();
  return {
    getAllAddress,
    createAddress,
    patchAddress,
    retriveAddress,
    destroyAddress
  };
};
