import uptoTwoDecimal from './uptoTwoDecimal';

const getDiscountedPrice = (pack) => {
  const discountPercentage = pack?.discounts || 0;
  const originalPrice = pack?.price || 0;
  const discountedPrice = originalPrice * (1 - discountPercentage / 100);
  return discountPercentage > 0
    ? uptoTwoDecimal(discountedPrice)
    : uptoTwoDecimal(originalPrice);
};

export default getDiscountedPrice;
