export default function flattenObject(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const flatObject = flattenObject(obj[key]);
      return {...acc, ...flatObject};
    } else {
      return {...acc, [key]: obj[key]};
    }
  }, {});
}
