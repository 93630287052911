import React from 'react';

import {useLocation} from 'react-router-dom';

export default function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useQueryKeys = () => {
  const locate = useLocation();

  // Function to parse query parameters
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(locate.search);
    const queryParams = {};

    // Iterate through each query parameter
    for (let param of searchParams.entries()) {
      const [key, value] = param;
      queryParams[key] = value;
    }

    return queryParams;
  };

  // Get all keys from the query parameters
  const queryParams = getQueryParams();
  const keys = Object.keys(queryParams);

  return keys;
};

// Custom hook to get a segment from pathname
export function usePathname(position) {
  const location = useLocation();
  const parts = location.pathname.split('/').filter(Boolean); // Remove empty segments
  return parts[position] || null;
}
