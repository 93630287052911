import {
  useMiscellaneousContext,
  useMiscellaneousActionContext
} from 'apps/miscellaneous/MiscellaneousProvider';

import {formatSchoolDropdown, schoolCheckBox} from '../helper';

export const useMiscellaneous = () => {
  const {
    misLoading,
    edges,
    stones,
    dishes,
    ceilings,
    school,
    schools,
    retriveSchool,
    deleteWorkerTimeSlot,
    workerAvailability
  } = useMiscellaneousContext();
  return {
    misLoading,
    edges,
    stones,
    dishes,
    ceilings,
    school,
    schools,
    retriveSchool,
    workerAvailability,
    deleteWorkerTimeSlot,

    schoolDropdown: formatSchoolDropdown(school),
    schoolCheckBox: schoolCheckBox(school)
  };
};

export const useMiscellaneousMethod = () => {
  const {
    getEdgeCutsDesigns,
    getStones,
    getDishes,
    getCeilings,
    getWorkerAvailability,
    postWorkerSchdule,

    postSchool,
    getSchool,
    getSchools,
    retrieveSchool
  } = useMiscellaneousActionContext();
  return {
    getEdgeCutsDesigns,
    getStones,
    getDishes,
    getCeilings,
    getWorkerAvailability,
    postWorkerSchdule,

    postSchool,
    getSchool,
    getSchools,
    retrieveSchool
  };
};
