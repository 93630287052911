import React, {useState} from 'react';
import styled from 'styled-components';
import SvgComp, {Dialog, DialogOverlay} from 'styles/common';
import {X} from 'assests/icons';
import MobileDrag from './MobileDrag';

const DialogContent = styled.div`
  margin-top: 2rem;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({padding}) => padding ?? '2rem'};

  p {
    font-size: 1.15rem;
    font-weight: 1rem;
  }

  @media only screen and (max-width: 55rem) {
    padding: ${({padding}) => padding ?? '0.5rem'};
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 2px solid ${({theme}) => theme.bg[400]};

  @media only screen and (max-width: 55rem) {
    padding: 1rem 0.5rem;
  }
`;

const Title = styled.h5`
  font-size: 1.35rem;
  font-weight: 600;
  color: ${({theme}) => theme.clr[200]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3rem);

  @media only screen and (max-width: 55rem) {
    font-size: 1rem;
  }
`;

const CloseDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999rem;
  width: 2.5rem;
  height: 2.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.bg[200]};
  }
`;

const DialogBox = ({
  setOpenDialog,
  openDialog,
  hideClose = false,
  padding,
  dialogBoxWidth,
  handleSubmit,
  title,
  sx,
  children
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  if (!openDialog) return null;
  if (isMobile) {
    return (
      <MobileDrag
        isMobile={isMobile}
        setIsMobile={setIsMobile}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        padding={padding}
        handleSubmit={handleSubmit}
        children={children}
        button={true}
      />
    );
  }

  return (
    <DialogOverlay>
      <Dialog maxWidth={dialogBoxWidth ?? '52rem'} sx={sx}>
        <TitleWrapper>
          <Title>{title ?? ''}</Title>
          {!hideClose && (
            <CloseDialog onClick={() => setOpenDialog(false)}>
              <SvgComp
                Icon={<X />}
                size='1.25rem'
                stroke={1}
                fillColor={'transparent'}
                strokeWidth={2}
              />
            </CloseDialog>
          )}
        </TitleWrapper>
        <DialogContent padding={padding}>{children}</DialogContent>
      </Dialog>
    </DialogOverlay>
  );
};

export default DialogBox;
