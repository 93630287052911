import axiosInstance from 'apps/apis';
import {ARTICLE} from 'apps/apis/allApis';
import React, {createContext, useContext, useState} from 'react';

const ArticleContext = createContext();
const ArticleActionContext = createContext();

export const ArticleProvider = ({children}) => {
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [articles, setArticles] = useState({
    count: 0,
    data: []
  });

  const [article, setArticle] = useState(null);

  const getAllArticles = async (page, page_size) => {
    try {
      setArticlesLoading(true);
      const {data} = await axiosInstance.get(
        `${ARTICLE}?page=${page}&page_size=${page_size}`
      );
      setArticlesLoading(false);
      setArticles({...articles, data: data.results, count: data.count});
    } catch (error) {
      console.error(error);
      setArticlesLoading(false);
    }
  };

  const getAnArticle = async (id) => {
    try {
      setArticlesLoading(true);
      const {data} = await axiosInstance.get(`${ARTICLE}${id}`);
      setArticlesLoading(false);
      setArticle(data);
    } catch (error) {
      console.error(error);
      setArticlesLoading(false);
    }
  };

  return (
    <ArticleContext.Provider value={{articles, articlesLoading, article}}>
      <ArticleActionContext.Provider value={{getAllArticles, getAnArticle}}>
        {children}
      </ArticleActionContext.Provider>
    </ArticleContext.Provider>
  );
};

export const useArticleContext = () => {
  return useContext(ArticleContext);
};

export const useArticleActionContext = () => {
  return useContext(ArticleActionContext);
};

export default ArticleProvider;
