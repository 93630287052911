import calculateDuration from './calculateDuration';

export function perMinutePriceCalculator(prof, time) {
  const duration_in_minute = calculateDuration(
    time?.end,
    time?.start,
    false,
    'min'
  );
  const cost = (parseInt(prof.charge) / 60) * duration_in_minute;

  return cost;
}

export function ceilingOrderCalculator(ceilings, areas) {
  // mutiple ceiling and their input area cost
  const prices = [];
  const calculatedValues = [];

  if (Array.isArray(ceilings)) {
    ceilings?.forEach((ceiling) => {
      const area = areas[ceiling.name];
      const unitAreaCharge = parseInt(ceiling.charge, 10);
      const totalPrice = unitAreaCharge * (area ?? 0);

      calculatedValues.push(totalPrice);
      prices.push(
        {label: `${ceiling.name} area`, value: `${area ?? 0} ft²`},
        {label: `${ceiling.name} cost`, value: `PKR ${ceiling.charge}/ft²`},
        {
          label: `${unitAreaCharge} PKR x ${area ?? 0} ft²`,
          value: `PKR ${totalPrice}`
        }
      );
    });
    return {
      calculatedValues,
      prices
    };
  }
}

export function chefOrderCalculator(dishes, numParticipants) {
  const prices = [];
  const calculatedValues = [];

  if (Array.isArray(dishes)) {
    dishes?.forEach((dish) => {
      const dinners = numParticipants[dish.name];
      const baseCharge = parseInt(dish.charge, 10);
      const additionalCharge = parseInt(dish.additional, 10);

      if (dinners !== undefined) {
        const totalPrice =
          dinners <= 6
            ? baseCharge
            : baseCharge + (dinners - 6) * additionalCharge;

        calculatedValues.push(totalPrice);
        prices.push(
          {
            label: `${dish.name} ${dinners} dinners x ${baseCharge}`,
            value: `PKR ${totalPrice}`
          },
          {
            label: `No. ${dish.name} dinners`,
            value: `${dinners} dinners`
          }
        );
      } else {
        calculatedValues.push(baseCharge);
        prices.push(
          {
            label: `${dish.name} upto 6 dinners`,
            value: `PKR ${baseCharge}`
          },
          {
            label: `No. ${dish.name} dinners`,
            value: dinners ?? '6 dinners'
          }
        );
      }
    });
    return {
      calculatedValues,
      prices
    };
  }
}
