import {
  useNotificationContext,
  useNotificationActionContext
} from 'apps/notifications/NotificationsProvider';

export const useNotification = () => {
  const {notification} = useNotificationContext();
  return {notification};
};

export const useNotificationMethod = () => {
  const {showNotification, hideNotification} = useNotificationActionContext();
  return {showNotification, hideNotification};
};
