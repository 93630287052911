export default function servicesOfferDropDown(services) {
  let dropdownList = [];
  for (let item of services) {
    let service = {
      label: item.name,
      value: item.id
    };
    dropdownList.push(service);
  }
  return dropdownList;
}
