// Generate Diffie-Hellman parameters
async function generateDHParameters() {
  const params = await window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256'
    },
    true,
    ['deriveKey', 'deriveBits']
  );
  return params;
}

// Generate a key pair
export async function generateKeyPair() {
  const keyPair = await generateDHParameters();
  const publicKeyJwk = await window.crypto.subtle.exportKey(
    'jwk',
    keyPair.publicKey
  );
  const privateKeyJwk = await window.crypto.subtle.exportKey(
    'jwk',
    keyPair.privateKey
  );

  return {privateKey: privateKeyJwk, publicKey: publicKeyJwk};
}

// Compute shared secret
export default async function computeSharedSecret(privateKeyJwk, publicKeyJwk) {
  const privateKey = await window.crypto.subtle.importKey(
    'jwk',
    privateKeyJwk,
    {name: 'ECDH', namedCurve: 'P-256'},
    true,
    ['deriveKey', 'deriveBits']
  );

  const publicKey = await window.crypto.subtle.importKey(
    'jwk',
    publicKeyJwk,
    {name: 'ECDH', namedCurve: 'P-256'},
    true,
    []
  );

  const sharedSecret = await window.crypto.subtle.deriveBits(
    {name: 'ECDH', public: publicKey},
    privateKey,
    256
  );

  return sharedSecret;
}

// Derive a key from the shared secret
export async function deriveKey(sharedSecret, salt) {
  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    sharedSecret,
    {name: 'PBKDF2'},
    false,
    ['deriveBits', 'deriveKey']
  );

  const derivedKey = await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: new TextEncoder().encode(salt),
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    {name: 'AES-GCM', length: 256},
    true,
    ['encrypt', 'decrypt']
  );

  return derivedKey;
}

export async function encryptMessage(key, message) {
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encodedMessage = new TextEncoder().encode(message);

  const encryptedContent = await window.crypto.subtle.encrypt(
    {name: 'AES-GCM', iv: iv},
    key,
    encodedMessage
  );

  const encryptedContentArr = new Uint8Array(encryptedContent);
  const combined = new Uint8Array(iv.length + encryptedContentArr.length);
  combined.set(iv);
  combined.set(encryptedContentArr, iv.length);

  return btoa(String.fromCharCode.apply(null, combined));
}

// Decrypt a message
export async function decryptMessage(key, encryptedMessage) {
  const combined = new Uint8Array(
    atob(encryptedMessage)
      .split('')
      .map((char) => char.charCodeAt(0))
  );
  const iv = combined.slice(0, 12);
  const encryptedContentArr = combined.slice(12);

  const decryptedContent = await window.crypto.subtle.decrypt(
    {name: 'AES-GCM', iv: iv},
    key,
    encryptedContentArr
  );

  return new TextDecoder().decode(decryptedContent);
}

// Convert public key to string for transmission
export async function publicKeyToString(publicKey) {
  return JSON.stringify(publicKey);
}

// Convert string back to public key
export async function stringToPublicKey(publicKeyString) {
  return JSON.parse(publicKeyString);
}
