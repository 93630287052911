import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Hamburger = ({closeMenu, setCloseMenu}) => {
  return (
    <HamburgerContainer $closemenu={closeMenu} onClick={setCloseMenu}>
      <span className='line' />
      <span className='line' />
      <span className='line' />
    </HamburgerContainer>
  );
};

export default Hamburger;

const HamburgerContainer = styled.div`
  display: block;
  margin-inline-end: 0.45rem;

  & > span {
    margin-inline-start: 0.89rem;
    margin: 0.25rem;
    display: block;
    height: 2.5px;
    width: 100%;
    background-color: ${({theme}) => theme.clr[200]};
    color: #000;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out 0.2s;
    border-radius: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  span:nth-child(1),
  span:nth-child(3) {
    width: ${({$closemenu}) => ($closemenu ? '25px' : '26px')};
  }
  span:nth-child(2) {
    width: ${({$closemenu}) => ($closemenu ? '25px' : '0')};
  }
  span:nth-child(1) {
    -webkit-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, 8px) rotate(-45deg)'};
    -ms-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, 8px) rotate(-45deg)'};
    -o-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, 10px) rotate(-45deg)'};
    transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translate(0px, 9px) rotate(-45deg)'};
  }

  span:nth-child(3) {
    -webkit-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, -3px) rotate(45deg)'};
    -ms-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, -3px) rotate(45deg)'};
    -o-transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translateX(-10px, -3px) rotate(45deg)'};
    transform: ${({$closemenu}) =>
      $closemenu ? '' : 'translate(0px, -4px) rotate(45deg)'};
  }
`;

Hamburger.propTypes = {
  closeMenu: PropTypes.bool,
  setCloseMenu: PropTypes.func
};
